import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const CustomTable = ({
  hotelInfo,
  selectedYears,
  selectedColumns,
  totals, // Assuming totals are passed as props
  totalOfTotals,
  totalKeys,
}) => {
  const borderStyle = "2px solid gray"; // Adjust the color and thickness as needed
  const yearslength = selectedYears.length > 0;
  const renderYearHeaders = (selectedYears, prefix, borderStyle) =>
    selectedYears.map((year, index) => (
      <Th key={`${prefix}-${index}`} borderRight={borderStyle}>
        {year.label}
      </Th>
    ));

  return (
    <TableContainer>
      <Table variant="simple" size="md" border="1px solid #E2E8F0">
        <Thead>
          <Tr>
            <Th>Region</Th>
            <Th>Name</Th>
            <Th>Positioning</Th>
            <Th borderRight={borderStyle}>Number of Keys</Th>
            {yearslength &&
              selectedColumns.map((col, index) => (
                <>
                  <Th key={index}>{col.label}</Th>
                  <Th
                    borderRight={borderStyle}
                    colSpan={selectedYears.length}
                  ></Th>
                </>
              ))}
          </Tr>
          {selectedColumns.length > 0 && yearslength && (
            <Tr>
              <Th colSpan={4} borderRight={borderStyle}></Th>{" "}
              {/* Empty columns for hotel info */}
              {selectedColumns.map((col, index) => (
                <>
                  {renderYearHeaders(selectedYears, "col", null)}
                  <Th borderRight={borderStyle}>Total</Th>
                </>
              ))}
            </Tr>
          )}
        </Thead>
        <Tbody fontSize="sm">
          {hotelInfo.map((hotel, rowIndex) => (
            <Tr key={`hotel-row-${rowIndex}`}>
              <Td>{hotel.region}</Td>
              <Td>{hotel.name}</Td>
              <Td>{hotel.positioning}</Td>
              <Td borderRight={borderStyle}>
                {hotel.numberOfKeys && hotel.numberOfKeys.toLocaleString("en")}
              </Td>

              {yearslength &&
                selectedColumns.map((col, index) => (
                  <>
                    {selectedYears.map((year, index) => (
                      <Td key={`${year.label}-${year.value}`}>
                        {hotel.newData
                          ? hotel.newData[col.value][year.value]
                          : "-"}
                      </Td>
                    ))}
                    <Td borderRight={borderStyle}>
                      {hotel.yearsTotal?.[col.value] || "-"}
                    </Td>
                  </>
                ))}
            </Tr>
          ))}
          {/* Total Row */}
          <Tr>
            <Td colSpan={3}>Total (excl. in-planning-Fin. Pending assets)</Td>

            <Td borderRight={borderStyle}>
              {totalKeys.totalKeysExcl &&
                totalKeys.totalKeysExcl.toLocaleString("en")}
            </Td>

            {yearslength &&
              Object.keys(totals).length == selectedYears.length &&
              selectedColumns.map((col, index) => (
                <>
                  {selectedYears.map(
                    (year, index) => (
                      console.log(year.value, "flwfi", col.value),
                      (
                        <Td>
                          {totals[year.value][col.value]
                            ? totals[year.value][col.value]
                            : "-"}
                        </Td>
                      )
                    )
                  )}

                  <Td borderRight={borderStyle}>{totalOfTotals[col.value]}</Td>
                </>
              ))}
          </Tr>

          <Tr>
            <Td colSpan={3}>Total keys (incl. all assets)</Td>
            <Td borderRight={borderStyle}>
              {totalKeys.allTotalKeys &&
                totalKeys.allTotalKeys.toLocaleString("en")}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
