import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    Box,
    Text
} from "@chakra-ui/react";

export const Preview = ({ isOpen, onClose, data }) => {
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (data) {
            mapData(data);
        }
    }, [data]); // Added data as a dependency

    const mapData = (data) => {
        const tempData = {
            "Region": data.region,
            "Asset Name": data.assetName,
            "Positioning": data.positioning,
            "Version Number": data.versionNumber,
            "Last Modified By": data.lastModifiedBy,
            "Keys": data.keys,
            "Operator": data.operator,
            "Operating Model":data.operatingModel,
            "Brand": data.brand,
            "Construction Start Year": data.constructionStartYear,
            "Opening Year": data.openingYear,
            "Service": data.service,
            "Include Branded Residence": data.includeBrandedResidence,
            "Number Of Branded Residences": data.numberOfBrandedResidences,
            "Staff Per Key": data.staffPerKey,
            "Comments": data.comments,
            "Updated  Positioning": data.unapprovedPositioning,
            "Updated Keys": data.unapprovedKeys,
            "Updated Opening Year": data.unapprovedOpeningYear,
            "Status": data.status,
        };
        setFormData(tempData);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Asset Details</ModalHeader>
                <ModalCloseButton />
                <form>
                    <ModalBody>
                        <Flex flexWrap="wrap">
                            {Object.entries(formData).map(([key, value], index) => (
                                <Box width={{ base: "100%", md: "50%" }} key={key} p={2}>
                                    <Text mb={2}>
                                        <strong>{key}:</strong> {value}
                                    </Text>
                                </Box>
                            ))}
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default Preview;
