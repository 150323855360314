import React, { useState, useEffect, useRef } from "react";

import {
  GetHotelKpisByIdAPI,
  AddHotelKpisAPI,
  GetHotelKpisDraftByIdAPI,
} from "../../../api/BenchmarksAPI";
import { GetProductionPositioningWithIsActive } from "../../../api/MasterDataAPI";

import history from "../../../history";

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";

import { NavLink, useLocation, useParams } from "react-router-dom";

import { Formik, Form } from "formik";
import {
  Box,
  Text,
  useDisclosure,
  Spacer,
  Badge,
  HStack,
  Tooltip,
  useToast,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { activeMenuItem } from "../../../utils/General";
import { MainTitle } from "../../../components/mainTitle/MainTitle";
import { MainBox } from "../../../components/mainBox/MainBox";
import { ModalAlert } from "../../../components/modalAlert/ModalAlert";
import { ToastAlert } from "../../../components/toastAlert/ToastAlert";
import { HotelKpisStep1 } from "./HotelKpisStep1";
import { HotelKpisStep2 } from "./HotelKpisStep2";
import { HotelKpisStep3 } from "./HotelKpisStep3";
import { HotelKpisStep4 } from "./HotelKpisStep4";
import { HotelKpisStep5 } from "./HotelKpisStep5";
import { validationHotelKpisForm } from "./validationHotelKpisForm";

import checked from "../../../assets/images/icons/checked.svg";
import infoCircle from "../../../assets/images/icons/infoCircle.svg";
import info from "../../../assets/images/icons/Subtract.svg";

export const HotelKpis = () => {
  let location = useLocation();
  const accordRef = useRef(null);
  const [isRestore, setIsRestore] = useState(false);
  const activeMenu = activeMenuItem();
  const { id, draftId } = useParams();
  const [initFormVal, setInitFormVal] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(0);
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [isLoadingText, setIsLoadingText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [masterDataPos, setMasterDataPos] = useState([]);
  const [positioning, setPositioning] = useState([]);
  const [hotelKpis, setHotelKpis] = useState([]);
  const [validateStep, setValidateStep] = useState(false);
  const [modelWarningOpen, isModelWarningOpen] = useState(false);

  const toast = useToast();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const [dropdownObj, setDropdownObj] = useState({
    benchmarkLevel: [
      { value: "low", label: "Low" },
      { value: "average", label: "Average" },
      { value: "high", label: "High" },
    ],
  });

  const steps = [
    { title: "Edit Benchmark1" },
    { title: "Edit Benchmark2" },
    { title: "Edit Benchmark3" },
    { title: "Edit Benchmark4" },
    { title: "Edit Benchmark5" },
  ];

  let date;
  let lastMod;
  let submitModalTitle = "Hotel KPIs is successfully created.";
  if (!!hotelKpis.lastModifiedAt) {
    date = dateFnsParseISO(hotelKpis.lastModifiedAt);
    lastMod = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
  }

  const nextPage = () => {
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const closeToast = () => {
    toast.closeAll();
  };

  //OPEN ACCORDION THA HAVE ERROR
  const handleAccordErrors = (errors) => {
    console.log("err", errors);
    let copyErr = { ...errors };
    if (copyErr?.baseYear) {
      delete copyErr.baseYear;
    }
    if (copyErr?.comments) {
      delete copyErr.comments;
    }
    if (Object.keys(copyErr)?.length > 0) {
      let mainObjKey = Object.keys(copyErr)[0];
      let subObjKey = copyErr[`${mainObjKey}`];
      let accordClassName = Object?.keys(subObjKey)[0];
      if (Object?.keys(subObjKey)[0] == "otherRevenues") {
        let revArr = Object?.keys(subObjKey)?.reverse();
        accordClassName = revArr[0];
      }

      accordRef.current
        .querySelectorAll(`.chakra-accordion__button`)
        .forEach((el, ind) => {
          if (el.classList.contains(`${accordClassName}`)) {
            if (el.ariaExpanded == "false") {
              el.click();
            }
          }
        });
    }
  };

  const handleOnSubmit = (formikValue, isRestore) => {
    if (!!id) {
      let hotelKpisParse = JSON.parse(hotelKpis.formFields);
      let baseYearDate = new Date(formikValue.baseYear).getFullYear();
      let commentsLogic =
        hotelKpisParse.comments === formikValue.comments
          ? ""
          : formikValue.comments;

      const submitValues = {
        ...formikValue,
        isDraft: false,
        isRestore: !!isRestore,
        parentVersionNumber:
          hotelKpis.versionNumber || hotelKpis.parentVersionNumber,
        baseYear: `${baseYearDate}`,
        comments: commentsLogic,
      };

      AddHotelKpisAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      let baseYearDate = new Date(formikValue.baseYear).getFullYear();

      const submitValues = {
        ...formikValue,
        isDraft: false,
        parentVersionNumber: -1,
        baseYear: `${baseYearDate}`,
      };

      AddHotelKpisAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };

  const handleSaveDraft = (formikValue) => {
    if (!!id) {
      let hotelKpisParse = JSON.parse(hotelKpis.formFields);
      let baseYearDate = new Date(formikValue.baseYear).getFullYear();

      let commentsLogic =
        hotelKpisParse.comments === formikValue.comments
          ? ""
          : formikValue.comments;

      const submitValues = {
        ...formikValue,
        isDraft: true,
        versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
        baseYear: `${baseYearDate}`,
        comments: commentsLogic,
      };

      AddHotelKpisAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            onDraftClose();
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      let baseYearDate;
      if (formikValue.baseYear.length >= 1) {
        baseYearDate = new Date(formikValue.baseYear).getFullYear();
      } else {
        baseYearDate = "";
      }

      const submitValues = {
        ...formikValue,
        isDraft: true,
        baseYear: `${baseYearDate}`,
      };

      AddHotelKpisAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            setDisableSave(true);
            onErrorOpen();
            onSubmitClose();
            onDraftClose();
          } else if (res.success === true) {
            onDraftClose();
            setDisableSave(true);
            setErrorMessage("");
            onSubmitOpen();
          }
          return res;
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };

  useEffect(() => {
    let formField;

    if (!!id && !!draftId) {
      setErrorMessage("");
      GetHotelKpisDraftByIdAPI(id, draftId)
        .then(async (res) => {
          setIsLoading(false);

          setHotelKpis(res.data);
          let formFields = JSON.parse(res.data.formFields);
          const pos = await GetProductionPositioningWithIsActive();
          if (pos.data.length > 0) {
            let adrValuesDataKeys = ["adr", "fnbRevenues", "otherRevenues"];
            let dblOccDataKeys = ["cityHotels", "resorts"];
            let occRampDataKeys = ["year1", "year2", "year3", "year4", "year5"];
            let posDataKeys = [
              "averageGFAAndKey",
              "costPerKey_SAR",
              "costPerSqMOfGFA_SAR",
              "standardRoomSize",
            ];
            let staffDataKeys = ["adr", "staffAndKey", "staffPerQuarter"];

            let adrValuesNew = {};
            let staffingNew = {};
            let positioningValuesNew = {};
            let occupancyRampUpValuesNew = {};
            let doubleOccupancyRateValuesNew = {};
            let posProd = pos.data[0];
            if (posProd.status === "Production") {
              /* if (res.data.status !== "Production") { */
              if (posProd.lookups.length > 0) {
                adrValuesDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let newInitDataNames = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (formFields?.adrValues[`${key}`][index]) {
                      formArr.push(formFields?.adrValues[`${key}`][index]);
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                      newInitDataNames = [
                        ...newInitDataNames,
                        {
                          posName: el.label,
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                    } else {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                      newInitDataNames = [
                        ...newInitDataNames,
                        {
                          posName: el.label,
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                    }
                  });

                  setPositioning(newInitDataNames);
                  adrValuesNew = {
                    ...adrValuesNew,
                    [`${adrValuesDataKeys[ind]}`]: newInitData,
                  };
                });

                dblOccDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (
                      formFields?.doubleOccupancyRateValues[`${key}`][index]
                    ) {
                      formArr.push(
                        formFields?.doubleOccupancyRateValues[`${key}`][index]
                      );
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                    } else {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                    }
                  });

                  doubleOccupancyRateValuesNew = {
                    ...doubleOccupancyRateValuesNew,
                    [`${dblOccDataKeys[ind]}`]: newInitData,
                  };
                });
                posDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (formFields?.positioningValues[`${key}`][index]) {
                      formArr.push(
                        formFields?.positioningValues[`${key}`][index]
                      );
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                    } else {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                    }
                  });

                  positioningValuesNew = {
                    ...positioningValuesNew,
                    [`${posDataKeys[ind]}`]: newInitData,
                  };
                });
                occRampDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (formFields?.occupancyRampUpValues[`${key}`][index]) {
                      formArr.push(
                        formFields?.occupancyRampUpValues[`${key}`][index]
                      );
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                    } else {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                    }
                  });

                  occupancyRampUpValuesNew = {
                    ...occupancyRampUpValuesNew,
                    [`${occRampDataKeys[ind]}`]: newInitData,
                  };
                });
                staffDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (formFields?.staffingValues[`${key}`][index]) {
                      formArr.push(formFields?.staffingValues[`${key}`][index]);
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      if (key !== "staffPerQuarter") {
                        newInitData = [
                          ...newInitData,
                          {
                            positioningId: findObj.positioningId,
                            low: findObj.low,
                            high: findObj.high,
                            average: findObj.average,
                          },
                        ];
                      } else {
                        newInitData = [
                          ...newInitData,
                          {
                            positioningId: findObj.positioningId,
                            value: findObj.value,
                          },
                        ];
                      }
                    } else {
                      if (key !== "staffPerQuarter") {
                        newInitData = [
                          ...newInitData,
                          {
                            positioningId: el.value,
                            low: "",
                            high: "",
                            average: "",
                          },
                        ];
                      } else {
                        newInitData = [
                          ...newInitData,
                          {
                            positioningId: el.value,
                            value: [0, 0, 0, 0, 0, 0, 0, 0, 100],
                          },
                        ];
                      }
                    }
                  });

                  staffingNew = {
                    ...staffingNew,
                    [`${staffDataKeys[ind]}`]: newInitData,
                  };
                });

                let newObj = {
                  baseYear: formFields.baseYear
                    ? dateFnsParseISO(formFields.baseYear, "yyyy", new Date())
                    : "",
                  benchmarkLevel: formFields.benchmarkLevel,
                  adrValues: { ...adrValuesNew },
                  staffingValues: { ...staffingNew },
                  occupancyRampUpValues: { ...occupancyRampUpValuesNew },
                  positioningValues: { ...positioningValuesNew },
                  doubleOccupancyRateValues: {
                    ...doubleOccupancyRateValuesNew,
                  },
                  comments: res.data.comments,
                };
                setIsLoading(false);
                setMasterDataPos(posProd.lookups);
                return {
                  res: res,
                  dataWithNewPos: newObj,
                };
              }
              /* } */
              return {
                res: res,
              };
            }
          } else {
            setIsLoadingText("You should add a positioning");
          }
        })
        .then((posObj) => {
          formField = JSON.parse(posObj.res.data.formFields);

          //we need to fix this
          setInitFormVal(posObj.dataWithNewPos);

          setHotelKpis(posObj.res.data);
          /* } */
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else if (!!id) {
      setErrorMessage("");
      GetHotelKpisByIdAPI(id)
        .then(async (res) => {
          setIsLoading(false);

          setHotelKpis(res.data);
          let formFields = JSON.parse(res.data.formFields);
          const pos = await GetProductionPositioningWithIsActive();
          if (pos.data.length > 0) {
            let adrValuesDataKeys = ["adr", "fnbRevenues", "otherRevenues"];
            let dblOccDataKeys = ["cityHotels", "resorts"];
            let occRampDataKeys = ["year1", "year2", "year3", "year4", "year5"];
            let posDataKeys = [
              "averageGFAAndKey",
              "costPerKey_SAR",
              "costPerSqMOfGFA_SAR",
              "standardRoomSize",
            ];
            let staffDataKeys = ["adr", "staffAndKey", "staffPerQuarter"];

            let adrValuesNew = {};
            let staffingNew = {};
            let positioningValuesNew = {};
            let occupancyRampUpValuesNew = {};
            let doubleOccupancyRateValuesNew = {};
            let posProd = pos.data[0];
            if (posProd.status === "Production") {
              /* if (res.data.status !== "Production") { */
              if (posProd.lookups.length > 0) {
                adrValuesDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let newInitDataNames = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (formFields?.adrValues[`${key}`][index]) {
                      formArr.push(formFields?.adrValues[`${key}`][index]);
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                      newInitDataNames = [
                        ...newInitDataNames,
                        {
                          posName: el.label,
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                    } else {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                      newInitDataNames = [
                        ...newInitDataNames,
                        {
                          posName: el.label,
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                    }
                  });

                  setPositioning(newInitDataNames);
                  adrValuesNew = {
                    ...adrValuesNew,
                    [`${adrValuesDataKeys[ind]}`]: newInitData,
                  };
                });

                dblOccDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (
                      formFields?.doubleOccupancyRateValues[`${key}`][index]
                    ) {
                      formArr.push(
                        formFields?.doubleOccupancyRateValues[`${key}`][index]
                      );
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                    } else {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                    }
                  });

                  doubleOccupancyRateValuesNew = {
                    ...doubleOccupancyRateValuesNew,
                    [`${dblOccDataKeys[ind]}`]: newInitData,
                  };
                });
                posDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (formFields?.positioningValues[`${key}`][index]) {
                      formArr.push(
                        formFields?.positioningValues[`${key}`][index]
                      );
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                    } else {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                    }
                  });

                  positioningValuesNew = {
                    ...positioningValuesNew,
                    [`${posDataKeys[ind]}`]: newInitData,
                  };
                });
                occRampDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (formFields?.occupancyRampUpValues[`${key}`][index]) {
                      formArr.push(
                        formFields?.occupancyRampUpValues[`${key}`][index]
                      );
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: findObj.positioningId,
                          low: findObj.low,
                          high: findObj.high,
                          average: findObj.average,
                        },
                      ];
                    } else {
                      newInitData = [
                        ...newInitData,
                        {
                          positioningId: el.value,
                          low: "",
                          high: "",
                          average: "",
                        },
                      ];
                    }
                  });

                  occupancyRampUpValuesNew = {
                    ...occupancyRampUpValuesNew,
                    [`${occRampDataKeys[ind]}`]: newInitData,
                  };
                });
                staffDataKeys.forEach((key, ind) => {
                  let newInitData = [];
                  let formArr = [];
                  posProd.lookups.forEach((el, index) => {
                    if (formFields?.staffingValues[`${key}`][index]) {
                      formArr.push(formFields?.staffingValues[`${key}`][index]);
                    }
                  });
                  posProd.lookups.forEach((el, index) => {
                    let findObj = formArr.find((obj) => {
                      return obj.positioningId === el.value;
                    });
                    if (findObj) {
                      if (key !== "staffPerQuarter") {
                        newInitData = [
                          ...newInitData,
                          {
                            positioningId: findObj.positioningId,
                            low: findObj.low,
                            high: findObj.high,
                            average: findObj.average,
                          },
                        ];
                      } else {
                        newInitData = [
                          ...newInitData,
                          {
                            positioningId: findObj.positioningId,
                            value: findObj.value,
                          },
                        ];
                      }
                    } else {
                      if (key !== "staffPerQuarter") {
                        newInitData = [
                          ...newInitData,
                          {
                            positioningId: el.value,
                            low: "",
                            high: "",
                            average: "",
                          },
                        ];
                      } else {
                        newInitData = [
                          ...newInitData,
                          {
                            positioningId: el.value,
                            value: [0, 0, 0, 0, 0, 0, 0, 0, 100],
                          },
                        ];
                      }
                    }
                  });

                  staffingNew = {
                    ...staffingNew,
                    [`${staffDataKeys[ind]}`]: newInitData,
                  };
                });

                let newObj = {
                  baseYear: formFields.baseYear,
                  benchmarkLevel: formFields.benchmarkLevel,
                  adrValues: { ...adrValuesNew },
                  staffingValues: { ...staffingNew },
                  occupancyRampUpValues: { ...occupancyRampUpValuesNew },
                  positioningValues: { ...positioningValuesNew },
                  doubleOccupancyRateValues: {
                    ...doubleOccupancyRateValuesNew,
                  },
                  comments: res.data.comments,
                };
                setIsLoading(false);
                setMasterDataPos(posProd.lookups);
                return {
                  res: res,
                  dataWithNewPos: newObj,
                };
              }
              /* } */
              /* return {
                res: res,
              }; */
            }
          } else {
            setIsLoadingText("You should add a positioning");
          }
        })
        .then((posObj) => {
          formField = JSON.parse(posObj.res.data.formFields);

          setInitFormVal(posObj.dataWithNewPos);

          setHotelKpis(posObj.res.data);
          /* } */
        })
        .catch((err) => {
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else {
      GetProductionPositioningWithIsActive()
        .then((res) => {
          if (res.data.length > 0) {
            let newInitData = [];
            let newInitDataNames = [];
            let posProd = res.data[0];
            if (posProd.status === "Production") {
              if (posProd.lookups.length > 0) {
                posProd.lookups.forEach((el) => {
                  newInitData = [
                    ...newInitData,
                    {
                      id: el.value,
                      type: el.label,
                    },
                  ];
                  newInitDataNames = [
                    ...newInitDataNames,
                    {
                      posName: el.label,
                      id: el.value,
                      type: el.label,
                    },
                  ];
                });

                setPositioning(newInitDataNames);
                setIsLoading(false);
                setMasterDataPos(posProd.lookups);
                return newInitData;
              }
            }
          } else {
            setIsLoadingText("You should add a positioning");
          }
        })
        .then((posArr) => {
          if (posArr && posArr.length >= 1) {
            let generalArr = [];

            posArr.map((item) => {
              generalArr = [
                ...generalArr,
                {
                  positioningId: item.id,
                  low: "",
                  high: "",
                  average: "",
                },
              ];
            });

            setInitFormVal({
              baseYear: "",
              benchmarkLevel: "average",
              adrValues: {
                adr: generalArr,
                fnbRevenues: generalArr,
                otherRevenues: generalArr,
              },
              staffingValues: {
                staffAndKey: generalArr,
                adr: generalArr,
                staffPerQuarter: generalArr,
              },
              occupancyRampUpValues: {
                year1: generalArr,
                year2: generalArr,
                year3: generalArr,
                year4: generalArr,
                year5: generalArr,
              },
              positioningValues: {
                standardRoomSize: generalArr,
                averageGFAAndKey: generalArr,
                costPerKey_SAR: generalArr,
                costPerSqMOfGFA_SAR: generalArr,
              },
              doubleOccupancyRateValues: {
                cityHotels: generalArr,
                resorts: generalArr,
              },
              comments: "",
            });
          } else {
            // setIsLoading(true);
            setDisableSave(true);
          }
        })
        .catch((err) => {
          setErrorMessage("You need to add a positioning from the master data");
          onErrorOpen();
        });
    }
    if (location?.state?.from === "Restore") {
      setIsRestore(true);
    }
  }, []);
  useEffect(() => {
    console.log("modelWarningOpen", modelWarningOpen);
    if (modelWarningOpen) {
      toast({
        position: "top-right",
        isClosable: true,
        render: () => (
          <ToastAlert
            title="You must fill all the fields."
            icon={infoCircle}
            closeToast={closeToast}
            type="Warning"
          />
        ),
      });
    }
    isModelWarningOpen(false);
  }, [modelWarningOpen]);

  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to discard the changes?`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);
  // console.log("positioning", positioning);
  return (
    <div>
      <>
        {id === undefined ? (
          <MainTitle title="CREATE HOTEL KPIs" transform="unset" />
        ) : (
          <MainTitle title="EDIT HOTEL KPIs" transform="unset" />
        )}

        {!isLoading ? (
          <MainBox>
            <Box className="table_content" pt={6} pb={6}>
              <Box display="flex">
                <Text className="title_md" display="flex" component="div">
                  STEP
                </Text>
                <Text ml={1}>
                  {page + 1}/{steps.length}
                </Text>
                {id !== undefined && (
                  <HStack w="100%" px="8">
                    <Badge
                      bg="rgba(0, 123, 181, 0.2)"
                      className="masterDataBadge"
                    >
                      Version Number:{" "}
                      {hotelKpis.status === "Draft" ||
                      hotelKpis.status === "InitialDraft"
                        ? hotelKpis.parentVersionNumber
                        : hotelKpis.versionNumber}
                    </Badge>
                    <Badge
                      bg="rgba(241, 136, 37, 0.3)"
                      className="masterDataBadge"
                    >
                      Last Modified By: {hotelKpis.lastModifiedBy}, {lastMod}
                    </Badge>

                    <Spacer />
                  </HStack>
                )}
              </Box>

              <Formik
                initialValues={initFormVal}
                enableReinitialize={true}
                validationSchema={validationHotelKpisForm[page]}
                validate={(values) => {
                  if (disableSave) {
                    setDisableSave(false);
                  }
                }}
              >
                {({
                  isSubmitting,
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  dirty,
                }) => (
                  <>
                    <Form
                      className="form_wrapper justify_content_start"
                      ref={accordRef}
                    >
                      {page === 0 && (
                        <HotelKpisStep1
                          nextPage={nextPage}
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          onCancelOpen={onCancelOpen}
                          onDraftOpen={onDraftOpen}
                          formikValues={values}
                          dropdownObj={dropdownObj}
                          setDisableSave={setDisableSave}
                          disableSave={disableSave}
                          setDismissSave={setDismissSave}
                          positioning={positioning}
                          isRestore={isRestore}
                          validateStep={validateStep}
                          setValidateStep={setValidateStep}
                          isModelWarningOpen={isModelWarningOpen}
                          modelWarningOpen={modelWarningOpen}
                          handleAccordErrors={handleAccordErrors}
                          closeToast={closeToast}
                        />
                      )}
                      {page === 1 && (
                        <HotelKpisStep2
                          nextPage={nextPage}
                          prevPage={prevPage}
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          onCancelOpen={onCancelOpen}
                          onDraftOpen={onDraftOpen}
                          formikValues={values}
                          handleBlur={handleBlur}
                          setDismissSave={setDismissSave}
                          disableSave={disableSave}
                          positioning={positioning}
                          isRestore={isRestore}
                          validateStep={validateStep}
                          setValidateStep={setValidateStep}
                          isModelWarningOpen={isModelWarningOpen}
                          modelWarningOpen={modelWarningOpen}
                          handleAccordErrors={handleAccordErrors}
                          closeToast={closeToast}
                        />
                      )}
                      {page === 2 && (
                        <HotelKpisStep3
                          nextPage={nextPage}
                          prevPage={prevPage}
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          onCancelOpen={onCancelOpen}
                          onDraftOpen={onDraftOpen}
                          formikValues={values}
                          setDismissSave={setDismissSave}
                          disableSave={disableSave}
                          positioning={positioning}
                          isRestore={isRestore}
                          validateStep={validateStep}
                          setValidateStep={setValidateStep}
                          isModelWarningOpen={isModelWarningOpen}
                          modelWarningOpen={modelWarningOpen}
                          handleAccordErrors={handleAccordErrors}
                          closeToast={closeToast}
                        />
                      )}
                      {page === 3 && (
                        <HotelKpisStep4
                          nextPage={nextPage}
                          prevPage={prevPage}
                          errors={errors}
                          touched={touched}
                          isRequired
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          onCancelOpen={onCancelOpen}
                          onDraftOpen={onDraftOpen}
                          formikValues={values}
                          setDismissSave={setDismissSave}
                          disableSave={disableSave}
                          positioning={positioning}
                          isRestore={isRestore}
                          validateStep={validateStep}
                          setValidateStep={setValidateStep}
                          isModelWarningOpen={isModelWarningOpen}
                          modelWarningOpen={modelWarningOpen}
                          handleAccordErrors={handleAccordErrors}
                          closeToast={closeToast}
                        />
                      )}
                      {page === 4 && (
                        <HotelKpisStep5
                          nextPage={nextPage}
                          prevPage={prevPage}
                          onOpenModal={onSubmitOpen}
                          errors={errors}
                          touched={touched}
                          isRequired
                          formikValues={values}
                          onChange={setFieldValue}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          onCancelOpen={onCancelOpen}
                          onSubmitOpen={onSubmitOpen}
                          onDraftOpen={onDraftOpen}
                          handleOnSubmit={handleOnSubmit}
                          setDismissSave={setDismissSave}
                          disableSave={disableSave}
                          hotelKpis={hotelKpis}
                          dirty={dirty}
                          positioning={positioning}
                          isRestore={isRestore}
                          validateStep={validateStep}
                          setValidateStep={setValidateStep}
                          isModelWarningOpen={isModelWarningOpen}
                          modelWarningOpen={modelWarningOpen}
                          handleAccordErrors={handleAccordErrors}
                          closeToast={closeToast}
                        />
                      )}

                      <ModalAlert
                        isOpen={isDraftOpen}
                        onClose={onDraftClose}
                        title="Are you sure you want to save this as draft?"
                        icon={infoCircle}
                        confirmationModal={true}
                        hasDesc={false}
                        haveSaveDraft={false}
                        path={`/HotelKpisHistory`}
                        pathDraft={`/HotelKpisHistory`}
                        onPressSaveDraft={() => handleSaveDraft(values)}
                      />
                      <ModalAlert
                        isOpen={isCancelOpen}
                        onClose={onCancelClose}
                        title="Are you sure you want to discard the changes?"
                        icon={infoCircle}
                        hasDesc={false}
                        confirmationModal={true}
                        path={`/HotelKpisHistory`}
                        onPressSaveDraft={() => handleSaveDraft(values)}
                        setDismissSave={() => setDismissSave(true)}
                      />
                    </Form>
                  </>
                )}
              </Formik>
            </Box>
          </MainBox>
        ) : (
          <>
            {isLoadingText !== "" ? (
              <Badge bg="#EBC03F26" className="masterDataBadge" width="100%">
                <HStack p="1">
                  <Tooltip
                    aria-label="A tooltip"
                    bg="#f4f4ed"
                    color="#1A1D1F"
                    borderRadius="5px"
                    border="1px solid #f4f4ed"
                    hasArrow
                    placement="right"
                    px={5}
                    py={2}
                  >
                    <img src={info} alt="tooltip icon" />
                  </Tooltip>
                  <Box fontSize={{ lg: 12, "2xl": 15 }}>
                    Ensure that you create a&nbsp;
                    <NavLink
                      // className={"myNav"}
                      style={{ color: "#007BB5", textDecoration: "underline" }}
                      to={`/MasterDataVersions/Positioning`}
                    >
                      Positioning
                    </NavLink>{" "}
                    to add HOTEL KPIs.
                  </Box>
                </HStack>
              </Badge>
            ) : (
              <Box paddingTop={28} paddingBottom={12}>
                <Center>
                  <Spinner size={"xl"} />
                </Center>
              </Box>
            )}
          </>
        )}

        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/HotelKpisHistory`}
        />
      </>
    </div>
  );
};
