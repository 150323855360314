import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Flex,
  Divider,
  GridItem,
  SimpleGrid,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Heading,
  HStack,
  useEditableControls,
  EditableInput,
  Editable,
  EditablePreview,
  Input,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";

import { InputFieldValidation } from "../../components/inputField/InputFieldValidation";
import { MainButton } from "../../components/button/MainButton";
import { MainTitle } from "../../components/mainTitle/MainTitle";

import rightArrow from "../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../assets/images/icons/Arrow-Left.svg";
import percent from "../../assets/images/icons/filled.svg";
import editRow from "../../assets/images/icons/editRow.svg";
import removeRow from "../../assets/images/icons/removeRow.svg";
import pen from "../../assets/images/icons/penBlue.svg";
import { CalculationButton } from "../../components/calculationModal/calculationButton";

export const AssetsListForm5FNB = ({
  dropdownObj,
  nextPage,
  prevPage,
  onOpenModal,
  onCancelOpen,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  validateErrors,
  setValidateErrors,
  setPercentage,
  page,
  setStepCompleted,
  percentage,
  formikValues,
  initFormVal,
  setInitFormVal,
  opStartDate,
  unsetStepWarning,
  setDismissSave,
  onDraftOpen,
  disableSave,
  isRestore,
  step,
  setStepWarning,
  stepperUpdate,
  setStepperUpdate,
  validateStep,
  setValidateStep,
  modelWarningOpen,
  isModelWarningOpen,
  closeModalPath,
  setFieldTouched,
  isFormDirty,
  setIsFormDirty,
  user,
}) => {
  console.log("formik values step 5", formikValues?.fnbPnl);

  const dataFetchedRef = useRef(false);
  const accordRef = useRef(null);
  const [outlet1, setOutlet1] = useState("Outlet 1");
  const [outlet2, setOutlet2] = useState("Outlet 2");
  const [outlet3, setOutlet3] = useState("Outlet 3");
  const [allDay, setAllDay] = useState("All-Day Dining");

  const collectValues = (obj, values = []) => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        collectValues(obj[key], values);
      } else {
        values.push(obj[key]);
      }
    }
    return values;
  };

  useEffect(() => {
    setOutlet1(formikValues.fnbPnl.aliases.outlet1);
    setOutlet2(formikValues.fnbPnl.aliases.outlet2);
    setOutlet3(formikValues.fnbPnl.aliases.outlet3);
    setAllDay(formikValues.fnbPnl.aliases.allDay);
  }, []);

  let formData = [];
  formData = collectValues(formikValues.fnbPnl);
  let emptyformData = [];

  formData.forEach((item, ind) => {
    if (ind >= 18 && ind <= 44) {
      if (item !== "" && item >= 0 && item <= 100) {
        emptyformData.push(item);
      }
    } else {
      if (item !== "") {
        emptyformData.push(item);
      }
    }
  });

  let isDisabledForm = false;
  if (emptyformData.length !== formData.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }

  const handleAccordErrors = () => {
    let openHigherAccord = true;

    formData.forEach((el, index) => {
      if (openHigherAccord) {
        if (el === "") {
          // console.log("index", index);
          if (index >= 66) {
            handleOpenAccord(0);
            openHigherAccord = false;
          }
          if (index < 66) {
            handleOpenAccord(1);
            openHigherAccord = false;
          }
        }
      }
    });
  };

  const handleOpenAccord = (accordIndex) => {
    if (
      accordRef.current.querySelectorAll(
        ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
      )[accordIndex].ariaExpanded === "false"
    ) {
      accordRef.current
        .querySelectorAll(
          ".form_wrapper .chakra-accordion__item .chakra-accordion__button"
        )
        [accordIndex].click();
    }
  };

  const handleEditChange = (newValue, labelName) => {
    switch (labelName) {
      case "outlet1":
        setOutlet1(newValue);
        break;
      case "outlet2":
        setOutlet2(newValue);
        break;
      case "outlet3":
        setOutlet3(newValue);
        break;
      case "allDay":
        setAllDay(newValue);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isDisabledForm) {
      setStepWarning(page);
      setStepperUpdate(true);
    }
    return () => {
      if (isDisabledForm && !stepperUpdate) {
        setStepWarning(page);
        setStepperUpdate(true);
      } else if (!isDisabledForm && stepperUpdate) {
        setStepCompleted(page);
        unsetStepWarning(page);
        setStepperUpdate(false);
      }
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    };
  }, [isDisabledForm]);

  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <Box display="flex" justifyContent="space-around" width="80px">
        <button {...getSubmitButtonProps()}>
          <img src={editRow} alt="edit" width="20px" />
        </button>
        <button {...getCancelButtonProps()}>
          <img src={removeRow} alt="edit" width="20px" />
        </button>
      </Box>
    ) : (
      <Box marginLeft="10px">
        <button {...getEditButtonProps()} size="md">
          <img src={pen} width="13px" alt="edit icon" />
        </button>
      </Box>
    );
  };

  const onEditModelName = (event, labelName) => {
    console.log("herererre", event);
    let defultData = "";
    if (event !== "") {
      setFieldValue(`fnbPnl.aliases.${labelName}`, event);

      if (setIsFormDirty && !isFormDirty) {
        setIsFormDirty(true);
      }
    } else {
      switch (labelName) {
        case "outlet1":
          defultData = "Outlet 1";
          setOutlet1(defultData);
          break;
        case "outlet2":
          defultData = "Outlet 2";
          setOutlet2(defultData);
          break;
        case "outlet3":
          defultData = "Outlet 3";
          setOutlet3(defultData);
          break;
        case "allDay":
          defultData = "All-Day Dining";
          setAllDay(defultData);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Box
        alignItems="flex-start"
        justifyContent="space-between"
        display="flex"
        width="100%"
        mb={5}
      >
        <MainTitle title="F&B P&l" />
        <CalculationButton formikValue={formikValues} />
      </Box>

      <Accordion
        width="100%"
        color="black"
        allowMultiple
        defaultIndex={[0, 1, 2, 3, 4, 5]}
        ref={accordRef}
      >
        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  General Assumptions
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <FormControl display="flex" mb='5' alignItems="center">
              <FormLabel htmlFor="nbPnl.enabled" mb="0">
                Detailed FnB Inputs ON/OFF
              </FormLabel>
              <Switch
                id="fnbPnl.enabled"
                name="fnbPnl.enabled"
                size="md"
                colorScheme="teal"
                isChecked={formikValues.fnbPnl.enabled}
                onChange={(event) => {
                  setFieldValue("fnbPnl.enabled", event.target.checked);
                  setIsFormDirty(true);

                }}
              />
            </FormControl>
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Guests per Occupied Room"
                      inputType="number"
                      inputId="guestPerOccRoom"
                      inputName="guestPerOccRoom"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      // isRequired
                      minNum="0"
                      placeholder="0"
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={true}
                      validateField={validateStep}
                      // isThousand={true}
                      onBlur={handleBlur}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="Staff Per Seat"
                      inputType="number"
                      inputId="fnbPnl.staffPerSeat"
                      inputName="fnbPnl.staffPerSeat"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      directVal={true}
                      onChange={setFieldValue}
                      values={formikValues?.fnbPnl?.staffPerSeat}
                      isDisabled={!formikValues.fnbPnl?.enabled}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Editable
                      value={allDay}
                      onChange={(value) => {
                        handleEditChange(value, "allDay");
                      }}
                      fontSize="15px"
                      // isPreviewFocusable={false}
                      width="100%"
                      display="flex"
                      alignItems="center"
                      className="title_md"
                      mb={5}
                      mt={3}
                      onSubmit={(value) => {
                        onEditModelName(value, "allDay");
                      }}
                    >
                      <EditablePreview />
                      {/* Here is the custom input */}
                      <Input
                        as={EditableInput}
                        borderRadius={0}
                        textAlign="left"
                      />
                      <EditableControls />
                    </Editable>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Editable
                      value={outlet1}
                      onChange={(value) => {
                        handleEditChange(value, "outlet1");
                      }}
                      fontSize="15px"
                      // isPreviewFocusable={false}
                      width="100%"
                      display="flex"
                      alignItems="center"
                      className="title_md"
                      mb={5}
                      mt={3}
                      onSubmit={(value) => {
                        onEditModelName(value, "outlet1");
                      }}
                    >
                      <EditablePreview />
                      {/* Here is the custom input */}
                      <Input
                        as={EditableInput}
                        borderRadius={0}
                        textAlign="left"
                      />
                      <EditableControls />
                    </Editable>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Editable
                      value={outlet2}
                      onChange={(value) => {
                        handleEditChange(value, "outlet2");
                      }}
                      fontSize="15px"
                      // isPreviewFocusable={false}
                      width="100%"
                      display="flex"
                      alignItems="center"
                      className="title_md"
                      mb={5}
                      mt={3}
                      onSubmit={(value) => {
                        onEditModelName(value, "outlet2");
                      }}
                    >
                      <EditablePreview />
                      {/* Here is the custom input */}
                      <Input
                        as={EditableInput}
                        borderRadius={0}
                        textAlign="left"
                      />
                      <EditableControls />
                    </Editable>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Editable
                      value={outlet3}
                      onChange={(value) => {
                        handleEditChange(value, "outlet3");
                      }}
                      fontSize="15px"
                      // isPreviewFocusable={false}
                      width="100%"
                      display="flex"
                      alignItems="center"
                      className="title_md"
                      mb={5}
                      mt={3}
                      onSubmit={(value) => {
                        onEditModelName(value, "outlet3");
                      }}
                    >
                      <EditablePreview />
                      {/* Here is the custom input */}
                      <Input
                        as={EditableInput}
                        borderRadius={0}
                        textAlign="left"
                      />
                      <EditableControls />
                    </Editable>
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  Number of Seats
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <SimpleGrid columns={{ sm: 4, md: 4 }} spacingX="15px" width="100%">
              <GridItem colSpan={4}>
                <SimpleGrid
                  columns={{ sm: 4, md: 4 }}
                  spacing="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`${allDay}`}
                      inputType="number"
                      inputId="fnbPnl.numberOfSeats.allDay"
                      inputName="fnbPnl.numberOfSeats.allDay"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      directVal={true}
                      onChange={setFieldValue}
                      values={formikValues?.fnbPnl?.numberOfSeats?.allDay}
                      isDisabled={isRestore || !formikValues.fnbPnl?.enabled}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`${outlet1}`}
                      inputType="number"
                      inputId="fnbPnl.numberOfSeats.signature"
                      inputName="fnbPnl.numberOfSeats.signature"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      directVal={true}
                      onChange={setFieldValue}
                      values={formikValues?.fnbPnl?.numberOfSeats?.signature}
                      isDisabled={isRestore || !formikValues.fnbPnl?.enabled}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`${outlet2}`}
                      // labelInfo={"Benchmark Data"}
                      inputType="number"
                      inputId="fnbPnl.numberOfSeats.lounge"
                      inputName="fnbPnl.numberOfSeats.lounge"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      directVal={true}
                      onChange={setFieldValue}
                      values={formikValues?.fnbPnl?.numberOfSeats?.lounge}
                      isDisabled={isRestore || !formikValues.fnbPnl?.enabled}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label={`${outlet3}`}
                      inputType="number"
                      inputId="fnbPnl.numberOfSeats.poolSide"
                      inputName="fnbPnl.numberOfSeats.poolSide"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      minNum="0"
                      placeholder="0"
                      directVal={true}
                      onChange={setFieldValue}
                      values={formikValues?.fnbPnl?.numberOfSeats.poolSide}
                      isDisabled={isRestore || !formikValues.fnbPnl?.enabled}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>

                  <GridItem colSpan={2}>
                    <InputFieldValidation
                      label="In-Room Dining (Hotel Keys)"
                      inputType="number"
                      inputId="numberOfKeys"
                      inputName="numberOfKeys"
                      errors={errors}
                      setIsFormDirty={setIsFormDirty}
                      isFormDirty={isFormDirty}
                      touched={touched}
                      isRequired
                      // directVal={true}
                      onChange={setFieldValue}
                      values={formikValues}
                      isDisabled={true}
                      validateField={validateStep}
                      isThousand={true}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                    />
                  </GridItem>
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  Number of Meals Served
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <HStack>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${allDay}`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.numberOfMeals.allDay.internal`}
                            inputName={`fnbPnl.numberOfMeals.allDay.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.numberOfMeals?.allDay
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            // smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.numberOfMeals.allDay.external`}
                            inputName={`fnbPnl.numberOfMeals.allDay.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.numberOfMeals?.allDay
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
                ml="7"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${outlet1}`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.numberOfMeals.signature.internal`}
                            inputName={`fnbPnl.numberOfMeals.signature.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.numberOfMeals?.signature
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            // smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.numberOfMeals.signature.external`}
                            inputName={`fnbPnl.numberOfMeals.signature.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.numberOfMeals?.signature
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </HStack>
            <HStack>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${outlet2}`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.numberOfMeals.lounge.internal`}
                            inputName={`fnbPnl.numberOfMeals.lounge.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.numberOfMeals?.lounge
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            // smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.numberOfMeals.lounge.external`}
                            inputName={`fnbPnl.numberOfMeals.lounge.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.numberOfMeals?.lounge
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
                ml="7"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {outlet3}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.numberOfMeals.poolSide.internal`}
                            inputName={`fnbPnl.numberOfMeals.poolSide.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.numberOfMeals?.poolSide
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            // smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.numberOfMeals.poolSide.external`}
                            inputName={`fnbPnl.numberOfMeals.poolSide.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.numberOfMeals?.poolSide
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </HStack>
            <Box maxW="32%" width="32%" alignSelf="flex-start">
              <InputFieldValidation
                label="In-Room Dining (Hotel Keys)"
                inputType="number"
                inputId="fnbPnl.numberOfMeals.inRoom"
                inputName="fnbPnl.numberOfMeals.inRoom"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0"
                directVal={true}
                onChange={setFieldValue}
                values={formikValues?.fnbPnl?.numberOfMeals?.inRoom}
                isDisabled={isRestore || !formikValues.fnbPnl?.enabled}
                validateField={validateStep}
                isThousand={true}
                onBlur={handleBlur}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  Average Capture Rates per Meal (as % Number of Average Guests
                  per Day)
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <Text
              className="title_md"
              display="flex"
              mb={5}
              mt={3}
              component="div"
            >
              {`${allDay} Dining`}
            </Text>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <Text
                fontSize={"12px"}
                display="flex"
                mb="4 !important"
                mt={3}
                component="div"
              >
                Internal Guests
              </Text>
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.allDay.internal.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.allDay.internal.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.allDay
                              ?.internal?.breakfast
                          }
                          // maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.allDay.internal.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.allDay.internal.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.allDay
                              ?.internal?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.allDay.internal.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.allDay.internal.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.allDay
                              ?.internal?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <Text
                fontSize={"12px"}
                display="flex"
                mb="4 !important"
                mt={3}
                component="div"
              >
                External Guests
              </Text>
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.allDay.external.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.allDay.external.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.allDay
                              ?.external?.breakfast
                          }
                          maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.allDay.external.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.allDay.external.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.allDay
                              ?.external?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.allDay.external.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.allDay.external.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.allDay
                              ?.external?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>

            <Text
              className="title_md"
              display="flex"
              mb={5}
              mt={3}
              component="div"
            >
              {`${outlet1}`}
            </Text>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <Text
                fontSize={"12px"}
                display="flex"
                mb="4 !important"
                mt={3}
                component="div"
              >
                Internal Guests
              </Text>
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.signature.internal.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.signature.internal.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.signature
                              ?.internal?.breakfast
                          }
                          maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.signature.internal.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.signature.internal.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.signature
                              ?.internal?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.signature.internal.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.signature.internal.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.signature
                              ?.internal?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <Text
                fontSize={"12px"}
                display="flex"
                mb="4 !important"
                mt={3}
                component="div"
              >
                External Guests
              </Text>
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.signature.external.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.signature.external.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.signature
                              ?.external?.breakfast
                          }
                          maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.signature.external.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.signature.external.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.signature
                              ?.external?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.signature.external.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.signature.external.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.signature
                              ?.external?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>

            <Text
              className="title_md"
              display="flex"
              mb={5}
              mt={3}
              component="div"
            >
              {`${outlet2}`}
            </Text>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <Text
                fontSize={"12px"}
                display="flex"
                mb="4 !important"
                mt={3}
                component="div"
              >
                Internal Guests
              </Text>
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.lounge.internal.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.lounge.internal.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.lounge
                              ?.internal?.breakfast
                          }
                          maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.lounge.internal.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.lounge.internal.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.lounge
                              ?.internal?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.lounge.internal.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.lounge.internal.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.lounge
                              ?.internal?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <Text
                fontSize={"12px"}
                display="flex"
                mb="4 !important"
                mt={3}
                component="div"
              >
                External Guests
              </Text>
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.lounge.external.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.lounge.external.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.lounge
                              ?.external?.breakfast
                          }
                          maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.lounge.external.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.lounge.external.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.lounge
                              ?.external?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.lounge.external.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.lounge.external.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.lounge
                              ?.external?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>

            <Text
              className="title_md"
              display="flex"
              mb={5}
              mt={3}
              component="div"
            >
              {outlet3}
            </Text>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <Text
                fontSize={"12px"}
                display="flex"
                mb="4 !important"
                mt={3}
                component="div"
              >
                Internal Guests
              </Text>
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.poolSide.internal.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.poolSide.internal.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.poolSide
                              ?.internal?.breakfast
                          }
                          maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.poolSide.internal.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.poolSide.internal.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.poolSide
                              ?.internal?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.poolSide.internal.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.poolSide.internal.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.poolSide
                              ?.internal?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <Text
                fontSize={"12px"}
                display="flex"
                mb="4 !important"
                mt={3}
                component="div"
              >
                External Guests
              </Text>
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.poolSide.external.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.poolSide.external.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.poolSide
                              ?.external?.breakfast
                          }
                          maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.poolSide.external.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.poolSide.external.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.poolSide
                              ?.external?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.poolSide.external.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.poolSide.external.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.poolSide
                              ?.external?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>

            <Text
              className="title_md"
              display="flex"
              mb={5}
              mt={3}
              component="div"
            >
              In-Room Dining (Hotel Keys)
            </Text>
            <Box
              maxW={["100%", "100%", "40%"]}
              width={["100%", "100%", "40%"]}
              alignSelf="flex-start"
            >
              <SimpleGrid
                columns={{ sm: 3, md: 3 }}
                spacingX="15px"
                width="100%"
              >
                <GridItem colSpan={3}>
                  <SimpleGrid
                    columns={{ sm: 3, md: 3 }}
                    spacing="15px"
                    width="100%"
                  >
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Breakfast"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.inRoom.breakfast`}
                          inputName={`fnbPnl.averageCaptureRate.inRoom.breakfast`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.inRoom
                              ?.breakfast
                          }
                          maxNum={100}
                          minNum={0}
                          smallIcon={percent}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                        />
                      </Box>
                    </GridItem>

                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Lunch"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.inRoom.lunch`}
                          inputName={`fnbPnl.averageCaptureRate.inRoom.lunch`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.inRoom
                              ?.lunch
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box textAlign="center">
                        <InputFieldValidation
                          label="Dinner"
                          uppercase={false}
                          inputType="number"
                          inputId={`fnbPnl.averageCaptureRate.inRoom.dinner`}
                          inputName={`fnbPnl.averageCaptureRate.inRoom.dinner`}
                          placeholder="0"
                          errors={errors}
                          setIsFormDirty={setIsFormDirty}
                          isFormDirty={isFormDirty}
                          touched={touched}
                          labelCenter={true}
                          isRequired
                          directVal={true}
                          onChange={setFieldValue}
                          values={
                            formikValues?.fnbPnl?.averageCaptureRate?.inRoom
                              ?.dinner
                          }
                          maxNum={100}
                          minNum={0}
                          isDisabled={
                            isRestore || !formikValues.fnbPnl?.enabled
                          }
                          validateField={validateStep}
                          isThousand={true}
                          setFieldTouched={setFieldTouched}
                          smallIcon={percent}
                        />
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </GridItem>
              </SimpleGrid>
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  Average Cover Spend (Food)
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <HStack>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${allDay} Dining`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverFood.allDay.internal`}
                            inputName={`fnbPnl.averageCoverFood.allDay.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverFood?.allDay
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverFood.allDay.external`}
                            inputName={`fnbPnl.averageCoverFood.allDay.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverFood?.allDay
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            smallLeftIcon={"SAR"}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
                ml="7"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${outlet1}`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverFood.signature.internal`}
                            inputName={`fnbPnl.averageCoverFood.signature.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverFood?.signature
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverFood.signature.external`}
                            inputName={`fnbPnl.averageCoverFood.signature.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverFood?.signature
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                            smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </HStack>
            <HStack>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${outlet2}`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverFood.lounge.internal`}
                            inputName={`fnbPnl.averageCoverFood.lounge.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverFood?.lounge
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverFood.lounge.external`}
                            inputName={`fnbPnl.averageCoverFood.lounge.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverFood?.lounge
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                            smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
                ml="7"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {outlet3}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverFood.poolSide.internal`}
                            inputName={`fnbPnl.averageCoverFood.poolSide.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverFood?.poolSide
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverFood.poolSide.external`}
                            inputName={`fnbPnl.averageCoverFood.poolSide.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverFood?.poolSide
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                            smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </HStack>
            <Box maxW="32%" width="32%" alignSelf="flex-start">
              <InputFieldValidation
                label="In-Room Dining (Hotel Keys)"
                inputType="number"
                inputId="fnbPnl.averageCoverFood.inRoom"
                inputName="fnbPnl.averageCoverFood.inRoom"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0"
                directVal={true}
                onChange={setFieldValue}
                values={formikValues?.fnbPnl?.averageCoverFood.inRoom}
                isDisabled={isRestore || !formikValues.fnbPnl?.enabled}
                validateField={validateStep}
                isThousand={true}
                onBlur={handleBlur}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem
          mb="4"
          borderBottom="none"
          className="accordion_item_custom"
        >
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton bg="#FCFCFC">
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  Avg Cover Spend (Beverages & Others)
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4} pt={6} border="1px" borderColor="#E6E6E6">
            <HStack>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${allDay} Dining`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverBeverage.allDay.internal`}
                            inputName={`fnbPnl.averageCoverBeverage.allDay.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverBeverage?.allDay
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverBeverage.allDay.external`}
                            inputName={`fnbPnl.averageCoverBeverage.allDay.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverBeverage?.allDay
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            smallLeftIcon={"SAR"}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
                ml="7"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${outlet1}`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverBeverage.signature.internal`}
                            inputName={`fnbPnl.averageCoverBeverage.signature.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverBeverage
                                ?.signature?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverBeverage.signature.external`}
                            inputName={`fnbPnl.averageCoverBeverage.signature.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverBeverage
                                ?.signature?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                            smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </HStack>
            <HStack>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {`${outlet2}`}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverBeverage.lounge.internal`}
                            inputName={`fnbPnl.averageCoverBeverage.lounge.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverBeverage?.lounge
                                ?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverBeverage.lounge.external`}
                            inputName={`fnbPnl.averageCoverBeverage.lounge.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverBeverage?.lounge
                                ?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                            smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
              <Box
                maxW={["100%", "100%", "40%"]}
                width={["100%", "100%", "40%"]}
                alignSelf="flex-start"
                ml="7"
              >
                <Text
                  fontSize={"12px"}
                  display="flex"
                  mb="4 !important"
                  mt={3}
                  component="div"
                >
                  {outlet3}
                </Text>
                <SimpleGrid
                  columns={{ sm: 2, md: 2 }}
                  spacingX="15px"
                  width="100%"
                >
                  <GridItem colSpan={2}>
                    <SimpleGrid
                      columns={{ sm: 2, md: 2 }}
                      spacing="15px"
                      width="100%"
                    >
                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="Internal Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverBeverage.poolSide.internal`}
                            inputName={`fnbPnl.averageCoverBeverage.poolSide.internal`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverBeverage
                                ?.poolSide?.internal
                            }
                            minNum={0}
                            // smallIcon={percent}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            smallLeftIcon={"SAR"}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                          />
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box textAlign="center">
                          <InputFieldValidation
                            label="External Guests"
                            uppercase={false}
                            inputType="number"
                            inputId={`fnbPnl.averageCoverBeverage.poolSide.external`}
                            inputName={`fnbPnl.averageCoverBeverage.poolSide.external`}
                            placeholder="0"
                            errors={errors}
                            setIsFormDirty={setIsFormDirty}
                            isFormDirty={isFormDirty}
                            touched={touched}
                            labelCenter={true}
                            isRequired
                            directVal={true}
                            onChange={setFieldValue}
                            values={
                              formikValues?.fnbPnl?.averageCoverBeverage
                                ?.poolSide?.external
                            }
                            minNum={0}
                            isDisabled={
                              isRestore || !formikValues.fnbPnl?.enabled
                            }
                            validateField={validateStep}
                            isThousand={true}
                            setFieldTouched={setFieldTouched}
                            smallLeftIcon={"SAR"}
                          />
                        </Box>
                      </GridItem>
                    </SimpleGrid>
                  </GridItem>
                </SimpleGrid>
              </Box>
            </HStack>
            <Box maxW="32%" width="32%" alignSelf="flex-start">
              <InputFieldValidation
                label="In-Room Dining (Hotel Keys)"
                inputType="number"
                inputId="fnbPnl.averageCoverBeverage.inRoom"
                inputName="fnbPnl.averageCoverBeverage.inRoom"
                errors={errors}
                setIsFormDirty={setIsFormDirty}
                isFormDirty={isFormDirty}
                touched={touched}
                isRequired
                minNum="0"
                placeholder="0"
                directVal={true}
                onChange={setFieldValue}
                values={formikValues?.fnbPnl.averageCoverBeverage.inRoom}
                isDisabled={isRestore || !formikValues.fnbPnl?.enabled}
                validateField={validateStep}
                isThousand={true}
                onBlur={handleBlur}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <Divider orientation="horizontal" mt="20px" mb="30px" />

      <Flex
        justifyContent={user == "manager" ? "end" : "space-between"}
        alignItems="center"
        width="100%"
      >
        {user !== "manager" ? (
          <MainButton
            title="Save Draft"
            btnStyle="transparent"
            disabledBtn={isRestore}
            fontColor="dark"
            onPress={() => {
              setDismissSave(false);
              onDraftOpen();
            }}
          />
        ) : (
          <></>
        )}
        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={
                closeModalPath !== "" || closeModalPath !== undefined
                  ? `${closeModalPath}`
                  : "/AssetsHistory"
              }
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />
            {/* <MainButton
              title="Next"
              reverseRow={true}
              marginLeft="2"
              icon={rightArrow}
              disabledBtn={isDisabledForm ? true : false}
              onPress={() => {
                nextPage();
                if (percentage < 55) {
                  setPercentage(55);
                }
                unsetStepWarning(page);
                setStepCompleted(page);
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            /> */}
            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  handleAccordErrors();
                  setValidateStep(true);

                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  nextPage();
                  if (percentage < 55) {
                    setPercentage(55);
                  }
                  unsetStepWarning(page);
                  setStepCompleted(page);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
