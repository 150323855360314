import React from "react";
import { ErrorMessage, Field, FastField } from "formik";
import { Text, Box, Tooltip } from "@chakra-ui/react";

import styles from "../forms.module.scss";

import view from "../../assets/images/icons/eye.svg";
import eyeSlash from "../../assets/images/icons/eye-slash.svg";
import { TooltipElement } from "../tooltip/TooltipElement";
import { NumericFormat } from "react-number-format";
// - You can hide the label throw putting labelVisibility to false

export const InputFieldValidation = ({
  label,
  inputType,
  inputId,
  inputName,
  isRequired = true,
  labelVisibility = true,
  placeholder,
  errors,
  touched,
  dColumn = false,
  minNum = "0",
  // maxNum = "10000000",
  maxNum,
  fullWidth = "",
  password = false,
  showPassHandler,
  icon,
  hintText,
  onChange,
  values,
  step,
  uppercase = true,
  labelCenter,
  mbBox,
  errorHeight0,
  leftIcon,
  leftIconText,
  currency,
  currencyConvertor,
  currVal = "0.00",
  isDisabled,
  noBg,
  smallIcon,
  textIcon,
  hideLabel,
  fs15,
  fs14,
  fs12,
  directVal = false,
  errorShape = false,
  validateField = false,
  labelInfo = "",
  isThousand,
  onValueChange,
  noInputIcon,
  smallLeftIcon = "",
  onBlur,
  smallerIcon = false,
  optional = false,
  acceptNegative = false,
  inputPL = false,
  setFieldTouched,
  setIsFormDirty,
  isFormDirty,
}) => {
  // console.log("inputName", inputName);
  let inputProps;

  if (values && !isThousand) {
    if (directVal) {
      inputProps = {
        value: values,
      };
    } else {
      inputProps = {
        value: values[inputName],
      };
    }
  } else if (parseInt(values) >= 0 && isThousand) {
    if (directVal) {
      inputProps = {
        value: values,
      };
    } else {
      inputProps = {
        value: values[inputName],
      };
    }
  } else if (values && isThousand) {
    if (directVal) {
      inputProps = {
        value: values,
      };
    } else {
      inputProps = {
        value: values[inputName],
      };
    }
  }

  let boxProps;
  let labelUppercaseClassname = uppercase ? `${styles.uppercase}` : "";
  labelUppercaseClassname += labelCenter ? ` ${styles.labelCenter} ` : "";

  if (mbBox) {
    boxProps = {
      mb: mbBox,
    };
  }

  let validateFieldLogic;
  if (optional) {
    if (validateField && directVal) {
      validateFieldLogic = validateField ? values != "" && values < 0 : false;
    } else if (validateField) {
      validateFieldLogic = validateField
        ? inputProps.value != "" && inputProps.value < 0
        : false;
    }
  } else {
    if (validateField && directVal) {
      if (acceptNegative) {
        validateFieldLogic = validateField
          ? values === "" || values > 100
          : false;
      } else {
        validateFieldLogic = validateField
          ? values === "" || values < 0
          : false;
      }
    } else if (validateField) {
      // console.log("validateField", validateField, inputProps.value);
      validateFieldLogic = validateField
        ? inputProps.value === "" || inputProps.value < 0
        : false;
    }
    // console.log("validateField 2", validateField);
  }
  if (
    `${inputName}`.includes("weightedADR") ||
    `${inputName}`.includes("totalPackageRateSARCalc") ||
    `${inputName}`.includes("weightedADRCalc") ||
    `${inputName}`.includes("packageRateCalc") ||
    `${inputName}`.includes("roomRateCalc") ||
    `${inputName}`.includes("otherCalc") ||
    `${inputName}`.includes("totalPackageRateUSDCalc") ||
    `${inputName}`.includes("totalPackageRate7DaysSARCalc") ||
    `${inputName}`.includes("totalPackageRate7DaysUSDCalc") ||
    `${inputName}`.includes("calcTotalDevelopmentCostPerKey")
  ) {
    validateFieldLogic = false;
  }

  let errorInputClassname =
    (errors[inputName] && touched[inputName] && errors[inputName]) ||
    validateFieldLogic
      ? `${styles.form_control} ${styles.form_control_error} ${
          dColumn ? "flex_column" : ""
        } `
      : `${styles.form_control} ${dColumn ? "flex_column" : ""}`;

  if (errorShape) {
    errorInputClassname += ` ${
      errors?.developmentAbsorptionLand && touched?.developmentAbsorptionLand
        ? styles.form_control_error
        : ""
    } `;
    errorInputClassname += ` ${
      errors?.managmentIncentiveFee && touched?.managmentIncentiveFee
        ? styles.form_control_error
        : ""
    } `;
    errorInputClassname += ` ${
      errors?.incentiveFee && touched?.incentiveFee
        ? styles.form_control_error
        : ""
    } `;
    errorInputClassname += ` ${
      errors?.annualSalesAbsorption && touched?.annualSalesAbsorption
        ? styles.form_control_error
        : ""
    } `;
  }

  let formInputClassname = labelVisibility
    ? `${styles.form_control_input} ${fullWidth}`
    : `${styles.form_control_input} ${styles.form_control_full}  ${fullWidth}`;

  formInputClassname += labelCenter ? ` ${styles.textAlignCenter} ` : "";

  formInputClassname += noBg ? ` ${styles.fieldNoBg} ` : "";

  formInputClassname += leftIcon ? ` ${styles.haveLeftIcon} ` : "";

  formInputClassname += fs15 ? ` ${styles.fs15} ` : "";

  formInputClassname += fs14 ? ` ${styles.fs14} ` : "";

  formInputClassname += fs12 ? ` ${styles.fs12} ` : "";

  formInputClassname += inputPL ? ` ${styles.inputPL} ` : "";

  formInputClassname += smallerIcon ? ` ${styles.pr5} ` : "";
  formInputClassname +=
    smallLeftIcon !== "" ? ` ${styles.haveSmallIconLeft} ` : "";
  formInputClassname +=
    !icon && smallLeftIcon !== "" ? ` ${styles.pr5_2} ` : "";

  formInputClassname += smallerIcon ? ` ${styles.haveSmallerIconLeft} ` : "";

  const haveOnChangeMethod = onChange ? (
    isThousand ? (
      <NumericFormat
        placeholder={placeholder}
        {...inputProps}
        name={inputName}
        id={inputName}
        onBlur={onBlur}
        valueIsNumericString={true}
        thousandSeparator={true}
        // decimalSeparator={"."}
        disabled={isDisabled}
        isAllowed={(values) => {
          const { floatValue } = values;
          if (!acceptNegative) {
            if (!!maxNum) {
              return (
                (floatValue >= parseInt(minNum) &&
                  floatValue <= parseInt(maxNum)) ||
                floatValue === undefined
              );
            } else {
              return floatValue >= parseInt(minNum) || floatValue === undefined;
            }
          } else {
            return true;
          }
        }}
        onChange={(val) => {
          let value;
          if (val.target.value == "") {
            value = "";
            if (setFieldTouched) {
              setFieldTouched(inputName, true);
            }
            onChange(inputName, value);
            if (setIsFormDirty && !isFormDirty) {
              setIsFormDirty(true);
            }
          } else {
            value = val.target.value;
            // console.log("value = val.target.value", value);
            // onChange(inputName, parseInt(value.replaceAll(",", "")));
            if (setFieldTouched) {
              setFieldTouched(inputName, true);
            }
            onChange(inputName, parseFloat(value.replaceAll(",", "")));
            if (setIsFormDirty && !isFormDirty) {
              setIsFormDirty(true);
            }
          }
        }}
      />
    ) : (
      <Field
        // <FastField
        disabled={isDisabled}
        type={inputType}
        name={inputName}
        id={inputName}
        placeholder={placeholder}
        min={minNum}
        max={maxNum}
        step={step}
        {...inputProps}
        onWheel={(e) => e.target.blur()}
        onChange={(val) => {
          let value;
          if (setIsFormDirty && !isFormDirty) {
            setIsFormDirty(true);
          }
          if (inputType == "number") {
            if (!isNaN(parseFloat(val.target.value))) {
              value = parseFloat(val.target.value);
            } else {
              value = val.target.value;
            }
          } else {
            value = val.target.value;
          }
          if (setFieldTouched) {
            setFieldTouched(inputName, true);
          }
          onChange(inputName, value);
        }}
        onBlur={onBlur}
      />
    )
  ) : (
    <Field
      disabled={isDisabled}
      type={inputType}
      name={inputName}
      id={inputName}
      placeholder={placeholder}
      min={minNum}
      max={maxNum}
      step={step}
      onWheel={(e) => e.target.blur()}
    />
  );

  return (
    <Box className={errorInputClassname} {...boxProps}>
      {labelVisibility && (
        <label
          style={hideLabel === true ? { visibility: "hidden" } : {}}
          htmlFor={inputId}
          className={`${fullWidth} ${labelUppercaseClassname}`}
        >
          {label}
          {labelInfo !== "" && (
            <Box className="tooltip_field5">
              <TooltipElement label="Prepopulated from Benchmark data." />
            </Box>
          )}
        </label>
      )}

      <div className={formInputClassname}>
        {haveOnChangeMethod}
        {noInputIcon && (
          <div className={styles.no_input_icon}>
            <span>{leftIconText}</span>
          </div>
        )}
        {smallLeftIcon !== "" && (
          <div
            className={
              smallerIcon
                ? styles.input_left_icon_smaller
                : styles.input_left_icon_small
            }
          >
            <span>{smallLeftIcon}</span>
          </div>
        )}
        {leftIcon && (
          <div className={styles.input_left_icon}>
            <span>{leftIconText}</span>
          </div>
        )}
        {password && (
          <div className="showPassword" onClick={() => showPassHandler()}>
            <img
              src={inputType === "password" ? view : eyeSlash}
              alt="eye icon"
            />
          </div>
        )}
        {icon && (
          <div className="iconic_input">
            <img src={icon} alt="icon" />
          </div>
        )}
        {smallIcon && (
          <div className=" iconic_input_small ">
            <img src={smallIcon} alt="icon" />
          </div>
        )}
        {textIcon === "SAR" ? (
          <Box className=" iconic_input_small2" fontSize="14px">
            {textIcon}
          </Box>
        ) : (
          <Box className=" iconic_input_small2" fontSize="12px">
            {textIcon}
          </Box>
        )}
        {isRequired && (
          <ErrorMessage
            name={inputName}
            component="div"
            style={{position:"absolute" }}
            className={`${styles.error} ${errorHeight0 ? styles.h_0 : ""} ${
              labelCenter ? styles.textAlignCenter : ""
            }`}
          />
        )}
        {currencyConvertor && (
          <Box>
            <Text className="hint_sm" textAlign="center">
              <span className="currVal">{currVal}</span>
              <span className="currType"> {currency}</span>
            </Text>
          </Box>
        )}
        {hintText && <Text marginTop="10px" className="hint_sm">{hintText}</Text>}
      </div>
    </Box>
  );
};
