import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Text,
  GridItem,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Heading,
  Flex,
  Divider,
  Badge,
} from "@chakra-ui/react";
import { FieldArray } from "formik";

import { InputFieldAverage } from "../../../components/inputField/InputFieldAverage";
import { MainButton } from "../../../components/button/MainButton";
import { MainTitle } from "../../../components/mainTitle/MainTitle";

import rightArrow from "../../../assets/images/icons/rightArrow2.svg";
import leftArrow from "../../../assets/images/icons/Arrow-Left.svg";
import percent from "../../../assets/images/icons/filled.svg";

export const HotelKpisStep2 = ({
  nextPage,
  prevPage,
  errors,
  touched,
  onChange,
  setFieldValue,
  handleBlur,
  onCancelOpen,
  onDraftOpen,
  formikValues,
  disableSave,
  setDismissSave,
  positioning,
  isRestore,
  validateStep,
  setValidateStep,
  isModelWarningOpen,
  closeToast,
  handleAccordErrors,
}) => {
  const [arrayKeys, setArrayKeys] = useState([]);
  const inputRef = useRef(null);
  let form2Data = [];
  let quarterIndexStart
  if (
    formikValues?.staffingValues?.adr &&
    formikValues?.staffingValues?.staffAndKey
  ) {
    formikValues.staffingValues.staffAndKey.forEach((item) => {
      form2Data.push(item);
    });
    formikValues.staffingValues.adr.forEach((item) => {
      form2Data.push(item);
    });
    quarterIndexStart = form2Data.length

    formikValues.staffingValues.staffPerQuarter.forEach((item) => {
      form2Data.push(item);
    });
  }
  let emptyform2Data = [];
  form2Data.forEach((item, index) => {
    if (index < quarterIndexStart) {
      if (
        item.low !== "" &&
        !isNaN(`${item.low}`) &&
        parseFloat(item.low) >= 0 &&
        item.high !== "" &&
        !isNaN(`${item.high}`) &&
        parseFloat(item.high) >= 0 &&
        item.average !== "" &&
        !isNaN(`${item.average}`) &&
        parseFloat(item.average) >= 0 &&
        parseFloat(item.low) <= parseFloat(item.high)
      ) {
        emptyform2Data.push(item);
      }
    } else {
      const value = item.value;
      const isInRange = value.every(val => val !== "" && val >= 0 && val <= 100);
      if (isInRange)
        emptyform2Data.push(item);
    }

  });

  let isDisabledForm = false;

  if (emptyform2Data.length !== form2Data.length) {
    isDisabledForm = true;
  }
  if (validateStep && !isDisabledForm) {
    setValidateStep(false);
  }
  let accordTitle = ["Managerial Staff (% of Total Staff)", "Staff/Key", "Staff per Quarter"];
  let arrKeys = [];
  if (formikValues?.staffingValues) {
    arrKeys = Object.keys(formikValues.staffingValues);
    if (JSON.stringify(arrayKeys) !== JSON.stringify(arrKeys)) {
      setArrayKeys(arrKeys);
    }
  }

  let renderAccrodionFields = (mData, pos, name) => {
    let counterMas = 0;

    counterMas = counterMas + 1;
    if (counterMas <= mData.length) {

      return (

        <AccordionItem mb="4" borderBottom="none">
          <Heading
            borderLeft="1px"
            borderBottom="1px"
            borderRight="1px"
            borderColor="#E6E6E6"
          >
            <AccordionButton
              bg="#FCFCFC"
              className={arrayKeys[pos]}
              onClick={() => {
                setTimeout(() => {
                  document
                    .querySelector(`.accordionList`)
                    .scrollIntoView({ block: "start" });
                }, 500);
              }}
            >
              <Box flex="1" textAlign="left">
                <Text
                  p="1"
                  pb="1"
                  pt="1"
                  fontSize="15px"
                  fontWeight="400"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                >
                  {accordTitle[pos]}
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>

          <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
            <FieldArray
              name={`${arrayKeys[pos]}`}
              render={(arrayHelper) => (
                <>
                  {formikValues.staffingValues[`${arrayKeys[pos]}`] !==
                    undefined &&
                    formikValues.staffingValues[`${arrayKeys[pos]}`].map(
                      (formArr, index) => {
                        let posObj = positioning.find((el) => {
                          if (el?.id) {
                            return formArr.positioningId === el.id;
                          } else {
                            return formArr.positioningId === el.positioningId;
                          }
                        });
                        return (
                          <Box key={index}>
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Text
                                fontSize="12px"
                                color="black"
                                fontWeight="400"
                                letterSpacing="0.04em"
                                textAlign="left"
                                textTransform="uppercase"
                                mb={4}
                                mt={3}
                              >
                                {posObj?.posName
                                  ? posObj.posName
                                  : `${formArr.positioningId}`
                                    .split("_")
                                    .join(" ")}
                              </Text>
                            </Box>
                            <GridItem colSpan={2}>
                              <SimpleGrid
                                columns={{ sm: 3, "2xl": 3 }}
                                spacing={{ sm: "25px", "2xl": "40px" }}
                                width={{ sm: "50%", "2xl": "40%" }}
                              >
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    xl: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="Low"
                                    inputType="number"
                                    inputId={`staffingValues.${arrayKeys[pos]}.${index}.low`}
                                    inputName={`staffingValues.${arrayKeys[pos]}.${index}.low`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.staffingValues[
                                        arrayKeys[pos]
                                      ][index]?.low
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    icon={
                                      accordTitle[pos] === "Staff/Key"
                                        ? ""
                                        : percent
                                    }
                                    disable={isRestore}
                                    validateField={validateStep}
                                    directVal={true}
                                    smallLeftIcon={
                                      accordTitle[pos] === "Staff/Key"
                                        ? "SAR"
                                        : ""
                                    }

                                  /* smallerIcon={
                                    accordTitle[pos] === "Staff/Key"
                                      ? true
                                      : false
                                  } */
                                  />
                                </GridItem>
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    xl: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="High"
                                    inputType="number"
                                    inputId={`staffingValues.${arrayKeys[pos]}.${index}.high`}
                                    inputName={`staffingValues.${arrayKeys[pos]}.${index}.high`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.staffingValues[
                                        arrayKeys[pos]
                                      ][index]?.high
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    icon={
                                      accordTitle[pos] === "Staff/Key"
                                        ? ""
                                        : percent
                                    }
                                    disable={isRestore}
                                    validateField={validateStep}
                                    directVal={true}
                                    smallLeftIcon={
                                      accordTitle[pos] === "Staff/Key"
                                        ? "SAR"
                                        : ""
                                    }
                                  /* smallerIcon={
                                    accordTitle[pos] === "Staff/Key"
                                      ? true
                                      : false
                                  } */
                                  />
                                </GridItem>
                                <GridItem
                                  w={{
                                    sm: "170px",
                                    md: "170px",
                                    lg: "170px",
                                    "2xl": "180px",
                                  }}
                                >
                                  <InputFieldAverage
                                    placeholder="0.00"
                                    label="Average"
                                    inputType="number"
                                    inputId={`staffingValues.${arrayKeys[pos]}.${index}.average`}
                                    inputName={`staffingValues.${arrayKeys[pos]}.${index}.average`}
                                    errors={errors}
                                    touched={touched}
                                    isRequired
                                    values={
                                      formikValues?.staffingValues[
                                        arrayKeys[pos]
                                      ][index]?.average
                                    }
                                    labelCenter={true}
                                    uppercase={false}
                                    //
                                    onChange={onChange}
                                    inputRef={inputRef}
                                    disable={true}
                                    avg={true}
                                    /*  icon={
                                      accordTitle[pos] === "Staff/Key"
                                        ? ""
                                        : percent
                                    } */
                                    noBg={true}
                                    validateField={false}
                                    directVal={true}
                                  />
                                </GridItem>
                              </SimpleGrid>
                              <Box>
                                {parseFloat(
                                  formikValues?.staffingValues[
                                    arrayKeys[pos]
                                  ][index]?.low
                                ) >
                                  parseFloat(
                                    formikValues?.staffingValues[
                                      arrayKeys[pos]
                                    ][index]?.high
                                  ) && (
                                    <Box
                                      color="#e14642"
                                      fontWeight="400"
                                      fontSize="10px"
                                      lineHeight="17px"
                                      letterSpacing="0.04em"
                                      marginTop="-5px"
                                    >
                                      Low value should be less than high value
                                    </Box>
                                  )}
                              </Box>
                            </GridItem>
                          </Box>
                        );
                      }
                    )}
                </>
              )}
            />
          </AccordionPanel>
        </AccordionItem>
      );
    }
  };

  let renderStaffPerQuarter = (mData, pos, name) => {
    return (
      <AccordionItem mb="4" borderBottom="none">
        <Heading
          borderLeft="1px"
          borderBottom="1px"
          borderRight="1px"
          borderColor="#E6E6E6"
        >
          <AccordionButton
            bg="#FCFCFC"
            className={arrayKeys[pos]}
            onClick={() => {
              setTimeout(() => {
                document
                  .querySelector(`.accordionList`)
                  .scrollIntoView({ block: "start" });
              }, 500);
            }}
          >
            <Box flex="1" textAlign="left">
              <Text
                p="1"
                pb="1"
                pt="1"
                fontSize="15px"
                fontWeight="400"
                letterSpacing="0.04em"
                textTransform="uppercase"
              >
                {accordTitle[pos]}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>

        <AccordionPanel pb={4} border="1px" borderColor="#E6E6E6">
          <FieldArray
            name={`${arrayKeys[pos]}`}
            render={(arrayHelper) => (
              <>
                {formikValues.staffingValues[`${arrayKeys[pos]}`] !==
                  undefined &&
                  formikValues.staffingValues[`${arrayKeys[pos]}`].map(
                    (formArr, index) => {
                      let posObj = positioning.find((el) => {
                        if (el?.id) {
                          return formArr.positioningId === el.id;
                        } else {
                          return formArr.positioningId === el.positioningId;
                        }
                      });
                      return (
                        <Box key={index}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                          >
                            <Text
                              fontSize="12px"
                              color="black"
                              fontWeight="400"
                              letterSpacing="0.04em"
                              textAlign="left"
                              textTransform="uppercase"
                              mb={4}
                              mt={3}
                            >
                              {posObj?.posName
                                ? posObj.posName
                                : `${formArr.positioningId}`
                                  .split("_")
                                  .join(" ")}
                            </Text>
                          </Box>
                          <GridItem colSpan={12}>
                            <SimpleGrid
                              columns={{ sm: 10, "2xl": 10 }}
                              spacing={{ sm: "100px", "2xl": "70px" }}
                              width={{ sm: "100%", "2xl": "100%" }}
                            >

                              {formikValues.staffingValues[`${arrayKeys[pos]}`][index].value.map((value, valueIndex) => (
                                <GridItem
                                  key={valueIndex}
                                  w={{
                                    sm: "130px",
                                    md: "130px",
                                    xl: "130px",
                                    "2xl": "160px",
                                  }}
                                >


                                  <InputFieldAverage
                                    label={valueIndex == 8?`${24-(valueIndex * 3)}  before opening`:`${24-(valueIndex * 3)} Months` }
                                    uppercase={false}
                                    inputType="number"
                                    inputId={`staffingValues.${arrayKeys[pos]}.${index}.value.${valueIndex}`}
                                    inputName={`staffingValues.${arrayKeys[pos]}.${index}.value.${valueIndex}`}
                                    placeholder="0"
                                    errors={errors}
                                    touched={touched}
                                    labelCenter={true}
                                    isRequired
                                    onChange={onChange}
                                    values={formikValues?.staffingValues[arrayKeys[pos]][index]?.value[valueIndex]}
                                    minNum={0}
                                    maxNum={100}
                                    disable={valueIndex == 8 || false}
                                    directVal={true}
                                    icon={percent}
                                    isDisabled={isRestore}
                                    validateField={true}
                                    quarter={true}
                                  />
                                </GridItem>
                              ))}

                            </SimpleGrid>

                          </GridItem>
                        </Box>
                      );
                    }
                  )}
              </>
            )}
          />
        </AccordionPanel>
      </AccordionItem>
    )
  }
  return (
    <>
      <Box alignItems="flex-start" width="100%" mb={5}>
        <MainTitle title="HOTEL KPIs" transform="unset" />

        <Badge
          fontSize="16px"
          borderRadius="5px"
          bg=" rgba(235, 192, 63, 0.15);"
          color="#1D1F22"
          colorScheme="blackAlpha"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.06em"
          padding="10px 24px"
          mb="3"
        >
          Staffing
        </Badge>
      </Box>

      <Accordion
        width="100%"
        allowToggle
        color="black"
        ref={inputRef}
        defaultIndex={[0]}
        className="accordionList"
      >
        {formikValues?.staffingValues?.staffAndKey &&
          renderAccrodionFields(
            formikValues?.staffingValues?.staffAndKey,
            0,
            "staffAndKey"
          )}
        {formikValues?.staffingValues?.adr &&
          renderAccrodionFields(formikValues?.staffingValues?.adr, 1, "adr")}

      </Accordion>
      <Accordion
        width="100%"

        allowToggle
        color="black"
        defaultIndex={[0]}
        className="accordionList"
      >
        {formikValues?.staffingValues?.staffPerQuarter && renderStaffPerQuarter(formikValues?.staffingValues?.staffPerQuarter, 2, "staffPerQuarter")}
      </Accordion>

      <Divider orientation="horizontal" mt="30px" mb="30px" />

      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MainButton
          title="Save Draft"
          btnStyle="transparent"
          fontColor="dark"
          disabledBtn={isRestore}
          onPress={() => {
            setDismissSave(false);
            onDraftOpen();
          }}
        />

        <Box display="flex" gap="30px">
          {!disableSave ? (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              onPress={() => {
                setDismissSave(false);
                onCancelOpen();
              }}
            />
          ) : (
            <MainButton
              title="Cancel"
              btnStyle="transparent"
              fontColor="light"
              navLink={true}
              path={`/HotelKpisHistory`}
            />
          )}
          <Box display="flex" gap="20px">
            <MainButton
              title="Previous"
              btnStyle="gray"
              marginRight="2"
              icon={leftArrow}
              onPress={() => {
                prevPage();
                document.getElementById("main_content").scrollTo(0, 0);
              }}
            />

            {isDisabledForm ? (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  handleAccordErrors(errors);
                  setValidateStep(true);
                  handleAccordErrors(errors);
                  isModelWarningOpen(true);
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            ) : (
              <MainButton
                title="Next"
                reverseRow={true}
                marginLeft="2"
                icon={rightArrow}
                onPress={() => {
                  closeToast();
                  nextPage();
                  document.getElementById("main_content").scrollTo(0, 0);
                }}
              />
            )}

          </Box>
        </Box>
      </Flex>
    </>
  );
};
