import * as Yup from "yup";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
let tokenDecode;
if (Cookies.get("user_info")) {
  let token = Cookies.get("user_info");
  ////console.log("token", token);
  tokenDecode = jwt_decode(token);
  //remove this
  //tokenDecode = { roles: ["proponent"] };
  //tokenDecode?.roles[0] = "proponent"
}

export const validationAssetsForm = [
  //STEP 1
  Yup.object().shape({
    projectName: Yup.string().required("Required"),
    regionLookup: Yup.string().required("Required"),
    operatorLookup: Yup.string().required("Required"),
    brandLookup: Yup.string().required("Required"),
    managementContractLookup: Yup.string().required("Required"),
    positioningLookup: Yup.string().required("Required"),
    projectStatusLookup: Yup.string().required("Required"),
    projectStageLookup: Yup.string().required("Required"),
    numberOfKeys: Yup.number().min(0, "Required").required("Required"),
    plotSize: Yup.number().min(0, "Required").required("Required"),
    grossFloorArea: Yup.number().min(0, "Required").required("Required"),
    assetBaseYear: Yup.string().required("Required"),
    parkingArea: Yup.number().min(0, "Required").required("Required"),
    startDateDevTime: Yup.string().required("Required"),
    developmentTimeDurationMonths: Yup.number()
      .min(0, "Required")
      .max(60, "This should be less than or equal 60"),
    startDateOpTime: Yup.string().required("Required"),
    operationTimeDurationMonths: Yup.number()
      .min(60, "This should be between 60 and 600")
      .max(600, "This should be between 60 and 600")
  }),

  //STEP 2
  Yup.object().shape({
    /* developmentAbsorptionLand: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .required("Required")
        .test("developmentAbsorptionLand", "sssssss", (values) => {
          //console.log("values", values);
          return values;
        })

    ), */
    developmentAbsorptionLand: Yup.array()
      .of(Yup.number().min(0, "Required").required("Required"))
      .test({
        name: "developmentAbsorptionLand",
        exclusive: false,
        params: {},
        message: "   ",
        test: function (value) {
          // //console.log("values", value);
          let valSum = 0;
          let isInteger = true;
          let countZero = 0;
          value.forEach((item) => {
            valSum += item;
            // //console.log("integer", !Number.isInteger(item));
            if (!Number.isInteger(item)) {
              isInteger = false;
            }
            if (item == 0) {
              countZero++;
            }
          });
          ////console.log("countZero", countZero);
          if (countZero === 5) {
            return value;
          }
          // //console.log("valSum", valSum, isInteger);
          if (valSum > 100 || valSum < 100 || !isInteger) {
            // //console.log("more than 100");
            return false;
          } else {
            return value;
          }
        },
      }),
    /*     devCostPerKey: Yup.number()
      .allow("")
      .min(0, "Required")
      .required("This field is required")
      .validateSync(""), */
    devCostPerKey: Yup.number().test({
      name: "devCostPerKey",
      exclusive: false,
      params: {},
      message: "Required",
      test: function (value) {
        ////console.log("dev value", value, value == undefined);
        //console.log("dev value", tokenDecode?.roles[0] == "admin");
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value > 0) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    /* landCost: Yup.number()
      .min(0, "Required")
      .required("This field is required"), */
    landCost: Yup.number().test({
      name: "landCost",
      exclusive: false,
      params: {},
      message: "Required",
      test: function (value) {
        ////console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value > 0) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    infraCost: Yup.number().test({
      name: "infraCost",
      exclusive: false,
      params: {},
      message: "Required",
      test: function (value) {
        ////console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value > 0) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    infraDepreciation: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    infraDevStartMonth: Yup.number()
      .min(1, "Required")
      .required("This field is required"),
    infraDevDuration: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    parkingCost: Yup.number().test({
      name: "parkingCost",
      exclusive: false,
      params: {},
      message: "Required",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    parkingDepreciation: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    /* parkingDevDuration: Yup.number()
      .min(0, "Required")
      .required("This field is required"), */
    bDownConsCost: Yup.number().test({
      name: "bDownConsCost",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0 && value <= 100) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    bDownDepConsCost: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    bDownFfeOse: Yup.number().test({
      name: "bDownFfeOse",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0 && value <= 100) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    bDownDepFfeOse: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    ffeDevelopmentDuration: Yup.number()
      .min(0, "Required")
      .required("This field is required")
      .test({
        name: "max",
        exclusive: false,
        params: {},
        message: "Must be less than or equal to duration (months) field",
        test: function (value) {
          // console.log(
          //   "compare",
          //   value,
          //   parseFloat(this.parent.developmentTimeDurationMonths)
          // );
          return value <= parseFloat(this.parent.developmentTimeDurationMonths);
        },
      }),
    bDownSoftCost: Yup.number().test({
      name: "bDownSoftCost",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0 && value <= 100) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    bDownDepSoftCost: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    bDownPreOpenTM: Yup.number().test({
      name: "bDownPreOpenTM",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0 && value <= 100) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    bDownDepPreOpenTM: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    preOpeningDevelopmentDuration: Yup.number()
      .min(0, "Required")
      .required("This field is required")
      .test({
        name: "max",
        exclusive: false,
        params: {},
        message: "Must be less than or equal to duration (months) field",
        test: function (value) {
          return value <= parseFloat(this.parent.developmentTimeDurationMonths);
        },
      }),
    bDownTsaFee: Yup.number().test({
      name: "bDownTsaFee",
      exclusive: false,
      params: {},
      message: "Required",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    bDownDepTsaFee: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    /* bDownITbudget: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    bDownDepITbudget: Yup.number()
      .min(0, "Required")
      .required("This field is required"), */
    bDownNeomDevFee: Yup.number().test({
      name: "bDownNeomDevFee",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0 && value <= 100) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    bDownDepNeomDevFee: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    bDownContCost: Yup.number().test({
      name: "bDownContCost",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0 && value <= 100) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    bDownDepContCost: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    bDownIniWorkCapital: Yup.number().test({
      name: "bDownIniWorkCapital",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0 && value <= 100) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    baseCost: Yup.number().test({
      name: "baseCost",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        if (tokenDecode?.roles[0] == "admin") {
          if (value == undefined || value === 0) {
            return 1;
          } else {
            if (!isNaN(value) && value >= 0 && value <= 100) {
              return value;
            } else {
              return false;
            }
          }
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
      },
    }),
    refurbishmentCost: Yup.number().test({
      name: "refurbishmentCost",
      exclusive: false,
      params: {},
      message: "Required and should be less than or equal 100",
      test: function (value) {
        //console.log("dev value", value, value == undefined);
        // if (tokenDecode?.roles[0] == "admin" ||) {
        if (value == undefined || value === 0) {
          return 1;
        } else {
          if (!isNaN(value) && value >= 0 && value <= 100) {
            return value;
          } else {
            return false;
          }
        }
        // } else {
        //   if (!isNaN(value) && value >= 0 && value <= 100) {
        //     return value;
        //   } else {
        //     return false;
        //   }
        // }
      },
    }),
  }),

  //STEP 3
  Yup.object().shape({
    taxRate: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    openCashBalance: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
    equity: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    annualInterestRate: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    debtTerm: Yup.number()
      .min(0, "Required")
      .required("This field is required")
      .test(
        "debtTerm",
        " Should be Integer number",
        (value) => Number.isInteger(value)
      ),
    gracePeriod: Yup.number()
      .min(0, "Required")
      .required("This field is required")
      .test(
        "gracePeriod",
        " Should be Integer number",
        (value) => Number.isInteger(value)
      ),
    debtProcFees: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    securityFees: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    commitmentFees: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    sellingCosts: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    TaxSaleVal: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    riskFreeRate: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    equityRiskPrem: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    countryRiskPrem: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    unleveredBeta: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    manualInput: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    buffer: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    ebitdaMulti: Yup.number()
      .min(0, "Required")
      .required("This field is required"),
  }),

  //STEP 4
  Yup.object().shape({
    roomType: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Required"),
        numKeys: Yup.number()
          .required("Required")
          .test("numKeys", "", (value) => Number.isInteger(value)),
        size: Yup.string().required("Required"),
        occRate: Yup.string()
          .required("Required")
          .min(0, "Required")
          .max(100, "This should be less than 100"),
      })
    ),
    occupancyIncRateYear: Yup.array().of(
      Yup.number().typeError('This must be a number')
        .min(-100, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
      // .test("occupancyIncRateYear", " Should be Integer number", (value) =>
      //   Number.isInteger(value)
      // )
    ),
    pricingInflator: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("This field is required"),
    // avgLenOfStay: Yup.number()
    //   .test(
    //     "is-decimal",
    //     "The amount should be a decimal with maximum two digits after dot",
    //     (val) => {
    //       let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
    //       if (val != undefined) {
    //         return patternTwoDigisAfterComma.test(val);
    //       }
    //       return true;
    //     }
    //   )
    avgLenOfStay: Yup.number()
      .min(0, "Required")
      .max(9.999, "This should be less than 10")
      .required("This field is required"),
    // guestPerOccRoom: Yup.number()
    //   .test(
    //     "is-decimal",
    //     "The amount should be a decimal with maximum two digits after dot",
    //     (val) => {
    //       let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
    //       if (val != undefined) {
    //         return patternTwoDigisAfterComma.test(val);
    //       }
    //       return true;
    //     }
    //   )
    guestPerOccRoom: Yup.number()
      .min(0, "Required")
      .max(9.999, "This should be less than 10")
      .required("This field is required"),
  }),

  //STEP 5
  Yup.object().shape({
    performaceAssitList2: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Required"),
        id: Yup.string().required("Required"),
        year: Yup.array().of(
          Yup.number().min(0, "Required").required("Required")
          // .test("year", " Should be Integer number", (value) =>
          //   Number.isInteger(value)
          // )
        ),
      })
    ),
    otherRevenuesPerformance: Yup.object().shape({
      foodAndBeverage: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("foodAndBeverage", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      meetingEvents: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("meetingEvents", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      banquet: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("banquet", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      recreation: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("recreation", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      spa: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("spa", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      otherProfitDepartments: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test(
        //   "otherProfitDepartments",
        //   " Should be Integer number",
        //   (value) => Number.isInteger(value)
        // )
      ),
      retail: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("retail", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      miscellaneous: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("miscellaneous", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      rental: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("rental", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      other1: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("other1", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      other2: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("other2", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
    }),
  }),

  Yup.object().shape({
    fnbPnl: Yup.object().shape({
      staffPerSeat:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
      enabled:Yup.boolean().required("Required"),
      aliases:Yup.object().shape({
        allDay: Yup.string().required("Required"),
        outlet1: Yup.string().required("Required"),
        outlet2: Yup.string().required("Required"),
        outlet3: Yup.string().required("Required"),
      }),
      
      numberOfSeats:Yup.object().shape({
        allDay:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        signature:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        lounge:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        poolSide:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
      }),

      numberOfMeals:Yup.object().shape({
        allDay:Yup.object().shape({
          internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        }),
        signature:Yup.object().shape({
          internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        }),
        lounge:Yup.object().shape({
          internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        }),
        poolSide:Yup.object().shape({
          internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        }),
        inRoom:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
      }),

      averageCaptureRate:Yup.object().shape({
        allDay:Yup.object().shape({
          internal:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100")
          }),
          external:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100")
          }),
        }),
        signature:Yup.object().shape({
          internal:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
          }),
          external:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
          }),
        }),
        lounge:Yup.object().shape({
          internal:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
          }),
          external:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
          }),
        }),
        poolSide:Yup.object().shape({
          internal:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
          }),
          external:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
          }),
        }),
          inRoom:Yup.object().shape({
            breakfast:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            lunch:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
            dinner:Yup.number().typeError('This must be a number').min(0, "Required").required("Required").max(100, "This should be less than  or equal 100"),
          }),
        }),
        averageCoverFood: Yup.object().shape({
          allDay:Yup.object().shape({
            internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
            external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          }),
          signature:Yup.object().shape({
            internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
            external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          }),
          lounge:Yup.object().shape({
            internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
            external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          }),
          poolSide:Yup.object().shape({
            internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
            external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          }),
          inRoom:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        }),
        averageCoverBeverage: Yup.object().shape({
          allDay:Yup.object().shape({
            internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
            external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          }),
          signature:Yup.object().shape({
            internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
            external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          }),
          lounge:Yup.object().shape({
            internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
            external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          }),
          poolSide:Yup.object().shape({
            internal:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
            external:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
          }),
          inRoom:Yup.number().typeError('This must be a number').min(0, "Required").required("Required"),
        })
    })
  }),

  // external visitors
  Yup.object().shape({
    externalVisitors: Yup.object().shape({
      numberOfVisitors: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("foodAndBeverage", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      foodAndBeveragePerVisitor: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("foodAndBeverage", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      recreationPerVisitor: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("recreation", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      spaPerVisitor: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("spa", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),
      otherOnePerVisitor: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test(
        //   "otherProfitDepartments",
        //   " Should be Integer number",
        //   (value) => Number.isInteger(value)
        // )
      ),
      otherTwoPerVisitor: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
        // .test("retail", " Should be Integer number", (value) =>
        //   Number.isInteger(value)
        // )
      ),

    }),
  }),

  // STEP 6 - Departmental Expenses
  Yup.object().shape({
    economiesOfScale: Yup.number()
      .min(-100, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),

    benchmarkType: Yup.string().required("Required"),

    positioning: Yup.string().required("Required"),

    expenseLevel: Yup.string().required("Required"),

    departmentalExpenses: Yup.object().shape({
      rooms: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
      }),

      foodAndBeverage: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
        }),

        costOfSales: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
      }),

      recreation: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
      }),

      spa: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
        }),
        costOfSales: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
      }),

      otherProfitDepartments: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
      }),

      retail: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        costOfSales: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
      }),
      miscellaneous: Yup.object().shape({
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
      }),
      rental: Yup.object().shape({
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        })
      }),

      other1: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        otherOneLabel: Yup.string().required("Required")
      }),

      other2: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          // .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .required("Required")
            .max(100, "This should be less than 100"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        otherTwoLabel: Yup.string().required("Required")
      }),
    }),
  }),

  // STEP 7 - Undistributed Expenses and Changes
  Yup.object().shape({
    undistributedExpenses: Yup.object().shape({
      adminGeneral: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        creditCardCommissions: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
      }),

      informationTelecom: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        // creditCardCommissions: Yup.object().shape({
        //   value: Yup.number()
        //     .min(0, "Required")
        //     .required("Required"),
        // }),
      }),

      propertyOperations: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        otherPayrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        // creditCardCommissions: Yup.object().shape({
        //   value: Yup.number()
        //     .min(0, "Required")
        //     .required("Required"),
        // }),
      }),

      salesMarketing: Yup.object().shape({
        payroll: Yup.object().shape({
          value: Yup.number().min(0, "Required").required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        other: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        otherExpensesYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        // creditCardCommissions: Yup.object().shape({
        //   value: Yup.number()
        //     .min(0, "Required")
        //     .required("Required"),
        // }),
      }),

      utilities: Yup.object().shape({
        // payroll: Yup.object().shape({
        //   value: Yup.number().min(0, "Required").required("Required"),
        //   fixed: Yup.number()
        //     .min(0, "Required")
        //     .required("Required"),
        // }),

        // payrollYearlyIncrement: Yup.object().shape({
        //   year1: Yup.number().min(0, "Required").required("Required"),
        //   year2: Yup.number().min(0, "Required").required("Required"),
        //   year3: Yup.number().min(0, "Required").required("Required"),
        // }),

        otherPerKey: Yup.object().shape({
          value: Yup.number()
            .min(0, "Required")
            // .max(100, "This should be less than 100")
            .required("Required"),
          fixed: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),
        payrollYearlyIncrement: Yup.object().shape({
          year1: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year2: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
          year3: Yup.number()
            // .min(0, "Required")
            .typeError("Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        }),

        // creditCardCommissions: Yup.object().shape({
        //   value: Yup.number()
        //     .min(0, "Required")
        //     .required("Required"),
        // }),
      }),
    }),

    fixedIncome: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    operationalInsurance: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    permitsAndLicenses: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    landLeaseFee: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    otherFixedCharges1: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
    propertyAndOtherTaxes: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    neomHotelsDevFee: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
    assetManagementFee: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    otherFixedCharges2: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
  }),

  // STEP 8 - Operator Terms and Staffing
  Yup.object().shape({
    managmentBaseFee: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
      /* .test("managmentBaseFee", " Should be Integer number", (value) =>
          Number.isInteger(value)
        ) */
    ),
    managmentFFEReserveFee: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
      // .test("managmentBaseFee", " Should be Integer number", (value) =>
      //   Number.isInteger(value)
      // )
    ),
    managmentIncentiveFee: Yup.array()
      .of(
        Yup.object().shape({
          ifGOPLessThanPercent: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")

            .required("Required"),
          feePercentOfGOP: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")

            .required("Required"),
        })
      )
      .test({
        name: "managmentIncentiveFee",
        exclusive: false,
        params: {},
        message: "   ",
        test: function (values) {
          //console.log("ifGOPLessThanPercent values", values);
          let countGreaterDesc = 0;
          values.forEach((inactive, ind) => {
            if (values.length - 2 >= ind) {
              ////console.log(inactive);
              //console.log(values[ind + 1].ifGOPLessThanPercent);
              //console.log(inactive.ifGOPLessThanPercent);
              if (
                values[ind + 1].ifGOPLessThanPercent >=
                inactive.ifGOPLessThanPercent
              ) {
                countGreaterDesc++;
              }
            }
          });
          //console.log("countGreaterDesc", countGreaterDesc);
          if (countGreaterDesc === 5) {
            return values;
          } else {
            return false;
          }
        },
      }),

    // franchiseFee: Yup.number()
    //   .min(0, "Currency Rate should be greater than or equal 0")
    //   .max(100, "This should be less than 100")
    //   .required("Required"),

    percentageCap: Yup.number()
      .min(0, "This should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),

    franchiseFee: Yup.array()
      .of(
        Yup.object().shape({
          fixedCharge: Yup.number().typeError('This must be a number')
            .min(0, "Required")
            .required("Required"),
          percentage: Yup.number()
            .min(0, "Required").typeError('This must be a number')
            .max(100, "This should be less than 100")
            .required("Required")
        })
      ).required(),

    revenueType: Yup.string().required(),

    includeFranchiseFee: Yup.string().required("Required"),
    franchiseFeeLabel: Yup.string().required("Required"),
    reservationContributionRevenueType: Yup.string().required("Required"),
    reservationContributionLabel: Yup.string().required("Required"),
    reservationContribution: Yup.array()
      .of(
        Yup.object().shape({
          fixedCharge: Yup.number().typeError('This must be a number')
            .min(0, "Required")
            .required("Required"),
          percentage: Yup.number()
            .min(0, "Required").typeError('This must be a number')
            .max(100, "This should be less than 100")
            .required("Required")
        })
      ).required("Required"),

    adminAndGeneral: Yup.array()
      .of(
        Yup.object().shape({
          fixedCharge: Yup.number().typeError('This must be a number')
            .min(0, "Required")
            .required("Required"),
          percentage: Yup.number()
            .min(0, "Required").typeError('This must be a number')
            .max(100, "This should be less than 100")
            .required("Required")
        })
      ).required("Required"),
    adminAndGeneralRevenueType: Yup.string().required("Required"),
    adminAndGeneralLabel: Yup.string().required("Required"),
    marketingContribution: Yup.string().required("Required"),

    marketingContributionField2: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    rewardProgram: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
    roomBookings: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
    operatorOtherProgramCost: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
    technicalFeeToOperator: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),

    leaseFixed: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    leaseVariable: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
    staffPerKey: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .required("Required")
    ),
    staffHousing: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .required("Required")
    ),
    staffPhasing: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .required("Required"),
    workingCapital: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
  }),

  // STEP 9 -  Branded Residences - GENERAL
  Yup.object().shape({
    unitMix: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required("Required"),
        numKeys: Yup.string().required("Required"),
        totalSellable: Yup.string().required("Required"),
        baseCost: Yup.string()
          .required("Required"),
        salesPrice: Yup.string()
          .required("Required"),
      })
    ),
    annualSalesAbsorption: Yup.array()
      .of(
        Yup.number()
          .min(0, "Required")
          .max(100, "This should be less than 100")
          .required("Required")
        /* .test("annualSalesAbsorption", " Should be Integer number", (value) =>
            Number.isInteger(value)
          ) */
      )
      .test({
        name: "annualSalesAbsorption",
        exclusive: false,
        params: {},
        message: "   ",
        test: function (value) {
          let valSum = 0;
          let isInteger = true;
          let countZero = 0;
          value.forEach((item) => {
            valSum += item;
            if (!Number.isInteger(item)) {
              isInteger = false;
            }
            if (item == 0) {
              countZero++;
            }
          });
          if (countZero === 5) {
            return value;
          }
          if (valSum > 100 || valSum < 100 || !isInteger) {
            return false;
          } else {
            return value;
          }
        },
      }),
    /*  annualSalesAbsorption: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
        .test("annualSalesAbsorption", " Should be Integer number", (value) =>
          Number.isInteger(value)
        )
    ), */
    Efficiency: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),
    opreatorContributions: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),
    adminExpenses: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),
    marketingSellingCost: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),
    residentialSaleFee: Yup.number().min(0, "Required").required("Required"),
    residentialConsultancyFee: Yup.number()
      .min(0, "Required")
      .required("Required"),
    adminCharges: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),
    shareOfResidence: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),
    minimumResidence: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),
    finalAssetSale: Yup.number()
      .min(0, "Required")
      .max(100, "This should be less than 100")
      .required("Required"),
  }),

  // STEP 10 - Branded Residences - Performance
  Yup.object().shape({
    /* brandedResidencesFields: Yup.object().shape({
      rentalPool: Yup.object().shape({
        type1SQM: Yup.number().min(0, "Required").required("Required"),
        type2SQM: Yup.number().min(0, "Required").required("Required"),
      }),

      avgNumberOfDaysPerAnnum: Yup.object().shape({
        type1SQM: Yup.number().min(0, "Required").required("Required"),
        type2SQM: Yup.number().min(0, "Required").required("Required"),
      }),

      stabilizedOccupancy: Yup.object().shape({
        type1SQM: Yup.number().min(0, "Required").required("Required"),
        type2SQM: Yup.number().min(0, "Required").required("Required"),
      }),

      adrPerUnit: Yup.object().shape({
        type1SQM: Yup.number().min(0, "Required").required("Required"),
        type2SQM: Yup.number().min(0, "Required").required("Required"),
      }),

      packageRate: Yup.object().shape({
        type1SQM: Yup.number().min(0, "Required").required("Required"),
        type2SQM: Yup.number().min(0, "Required").required("Required"),
      }),
    }), */
    brandedResidencesFields: Yup.object().shape({
      rentalPool: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required("Required"),
          id: Yup.string().required("Required"),
          typeField: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        })
      ),

      avgNumberOfDaysPerAnnum: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required("Required"),
          id: Yup.string().required("Required"),
          typeField: Yup.number().min(0, "Required").required("Required"),
        })
      ),

      stabilizedOccupancy: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required("Required"),
          id: Yup.string().required("Required"),
          typeField: Yup.number()
            .min(0, "Required")
            .max(100, "This should be less than 100")
            .required("Required"),
        })
      ),

      adrPerUnit: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required("Required"),
          id: Yup.string().required("Required"),
          typeField: Yup.number().min(0, "Required").required("Required"),
        })
      ),

      otherRev: Yup.array().of(
        Yup.number().min(0, "Required").required("Required")
      ),
    }),

    expenses1: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
    expenses2: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      // .max(100, "This should be less than 100")
      .required("Required"),
    expenses3: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),
    expenses4: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      // .max(100, "This should be less than 100")
      .required("Required"),
    expenses5: Yup.number()
      .min(0, "Currency Rate should be greater than or equal 0")
      .max(100, "This should be less than 100")
      .required("Required"),

    brandedBaseFee: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
      // .test("brandedBaseFee", " Should be Integer number", (value) =>
      //   Number.isInteger(value)
      // )
    ),

    brandedIncentiveFee: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
      // .test("brandedIncentiveFee", " Should be Integer number", (value) =>
      //   Number.isInteger(value)
      // )
    ),
    brandedFranchiseFee: Yup.array().of(
      Yup.number()
        .min(0, "Required")
        .max(100, "This should be less than 100")
        .required("Required")
      // .test("brandedFranchiseFee", " Should be Integer number", (value) =>
      //   Number.isInteger(value)
      // )
    ),
  }),

  // STEP 11 - Additional Details and Submit
  Yup.object().shape({
    comment: Yup.string().required("This field is required"),
    addComment: Yup.string().max(255, "255 characters are allowed")
  }),
];
