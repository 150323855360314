const roomTypeGroup = { type: "", numKeys: "", size: "", occRate: "" };
const unitMixGroup = {
  type: "",
  numKeys: "",
  totalSellable: "",
  baseCost: "",
  salesPrice: "",
};
let payRollIncrementsArr = {
  year1: "",
  year2: "",
  year3: "",
};
let otherExpensesIncrementsArr = {
  year1: "",
  year2: "",
  year3: "",
};
let payRollArr = {
  value: "",
  fixed: "",
};
let costOfSaleArr = {
  value: "",
  fixed: "",
};
let otherExpensesArr = {
  value: "",
  fixed: "",
};
let creditCardArr = {
  value: "",
};
let otherRevArr = Array(6).fill(0);
let developmentAbsorptionLand = Array(5).fill(0);
let managmentBaseFee = Array(5).fill("");
let managmentFFEReserveFee = Array(5).fill("");
let annualSalesAbsorption = Array(5).fill("");
let occupancyIncRateYear = Array(6).fill("");
let managmentIncentiveFee = Array(6).fill({
  ifGOPLessThanPercent: "",
  feePercentOfGOP: "",
});
let brandedBaseFee = Array(5).fill("");
let brandedIncentiveFee = Array(5).fill("");
let brandedFranchiseFee = Array(5).fill("");
let staffingArr = Array(5).fill("");

export const assetsFormInitialValues = (hotelData, developmentData) => {
  //console.log("assetlistInit", hotelData, developmentData);
  let { general, breakdown } = developmentData
    ? developmentData?.developmentCostValues
    : "";
  let { debt, risk } = developmentData
    ? developmentData?.debtAndRiskValues
    : "";
  let { baseFee, incentiveFee } = developmentData
    ? developmentData?.managementFees
    : "";
  //developmentData.managementFees.baseFee

  return {
    //links: [linksGroup],
    //STEP 1
    projectName: "",
    projectNameValue: "",
    assetType: "Standard",
    regionLookup: "",
    operatorLookup: "",
    brandLookup: "",
    managementContractLookup: "",
    positioningLookup: "",
    projectStatusLookup: "",
    projectStageLookup: "",
    plotSize: "",
    grossFloorArea: "",
    parkingArea: "",
    assetBaseYear: "",
    numberOfKeys: "",
    startDateDevTime: "", //we need to split this into two val (year, montsh)
    developmentTimeDurationMonths: "",
    developmentTimeCompletionDate: "",
    startDateOpTime: "",
    operationTimeDurationMonths: "",
    operationTimeEndDate: "",

    developmentTimeStartYear: "",
    developmentTimeStartMonth: "",
    developmentTimeStartDate: "",
    operationStartYear: "",
    operationStartMonth: "",
    operationStartDate: "",

    //STEP 2
    developmentAbsorptionLand: developmentAbsorptionLand,
    refurbishmentCost: "",
    refurbishmentYear: "",
    devCostPerKey: "",
    landCost: "",
    infraCost: "",
    infraDepreciation: developmentData
      ? general.infrastructure.depreciation
      : "",
    infraDevStartMonth: "",
    infraDevDuration: "",
    parkingCost: "",
    parkingDepreciation: developmentData ? general.parking.depreciation : "",
    //parkingDevDuration: "",
    bDownConsCost: developmentData ? breakdown.construction.cost : "",
    bDownDepConsCost: developmentData
      ? breakdown.construction.depreciation
      : "",
    bDownFfeOse: developmentData ? breakdown.FFE_OSE.cost : "",
    bDownDepFfeOse: developmentData ? breakdown.FFE_OSE.depreciation : "",
    ffeDevelopmentDuration: "",
    bDownSoftCost: developmentData ? breakdown.softCosts.cost : "",
    bDownDepSoftCost: developmentData ? breakdown.softCosts.depreciation : "",
    bDownPreOpenTM: developmentData ? breakdown.preOpening.cost : "",
    bDownDepPreOpenTM: developmentData ? breakdown.preOpening.depreciation : "",
    preOpeningDevelopmentDuration: "",
    bDownTsaFee: developmentData ? breakdown.TSA.cost : "",
    bDownDepTsaFee: developmentData ? breakdown.TSA.depreciation : "",
    // bDownITbudget: "",
    // bDownDepITbudget: "",
    bDownNeomDevFee: developmentData ? breakdown.NEOMDevelopmentFee.cost : "",
    bDownDepNeomDevFee: developmentData
      ? breakdown.NEOMDevelopmentFee.depreciation
      : "",
    bDownContCost: developmentData ? breakdown.contingency.cost : "",
    bDownDepContCost: developmentData ? breakdown.contingency.depreciation : "",
    bDownIniWorkCapital: developmentData ? breakdown.initialWorkingCapital : "",

    baseCost: "",

    //STEP 3
    taxRate: "",
    openCashBalance: "",
    equity: "",
    annualInterestRate: developmentData ? debt.annualInterestRate : "",
    debtTerm: developmentData ? debt.debtTerm : "",
    gracePeriod: developmentData ? debt.gracePeriod : "",
    debtProcFees: developmentData ? debt.debtProcessingFees : "",
    securityFees: developmentData ? debt.securityFees : "",
    commitmentFees: developmentData ? debt.commitmentFees : "",
    sellingCosts: "",
    TaxSaleVal: "",
    riskFreeRate: developmentData ? risk.riskFreeRate : "",
    equityRiskPrem: developmentData ? risk.equityRiskPremium : "",
    countryRiskPrem: developmentData ? risk.countryRiskPremium : "",
    unleveredBeta: developmentData ? risk.unleveredBeta : "",
    manualInput: developmentData ? risk.manualInput : "",
    buffer: "",
    ebitdaMulti: "",
    useCalculation: "",

    //STEP 4
    roomType: [roomTypeGroup],
    occupancyIncRateYear: occupancyIncRateYear,
    pricingInflator: "",
    avgLenOfStay: "",
    guestPerOccRoom: "",
    //calcTotalNumbersKeys: 0,

    //STEP 5
    otherRevenuesPerformance: {
      foodAndBeverage: otherRevArr,
      meetingEvents: otherRevArr,
      banquet: otherRevArr,
      recreation: otherRevArr,
      spa: otherRevArr,
      otherProfitDepartments: otherRevArr,
      retail: otherRevArr,
      miscellaneous: otherRevArr,
      rental: otherRevArr,
      other1: otherRevArr,
      other2: otherRevArr,
    },

    fnbPnl: {
      numberOfSeats: {
        allDay: 0,
        signature: 0,
        lounge: 0,
        poolSide: 0,
      },
      numberOfMeals: {
        allDay: {
          internal: 0,
          external: 0,
        },
        signature: {
          internal: 0,
          external: 0,
        },
        lounge: {
          internal: 0,
          external: 0,
        },
        poolSide: {
          internal: 0,
          external: 0,
        },
        inRoom: 0,
      },
      averageCaptureRate: {
        allDay: {
          internal: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
          },
          external: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
          },
        },
        signature: {
          internal: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
          },
          external: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
          },
        },
        lounge: {
          internal: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
          },
          external: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
          },
        },
        poolSide: {
          internal: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
          },
          external: {
            breakfast: 0,
            lunch: 0,
            dinner: 0,
          },
        },
        inRoom: {
          breakfast: 0,
          lunch: 0,
          dinner: 0,
        },
      },
      averageCoverFood: {
        allDay: {
          internal: 0,
          external: 0,
        },
        signature: {
          internal: 0,
          external: 0,
        },
        lounge: {
          internal: 0,
          external: 0,
        },
        poolSide: {
          internal: 0,
          external: 0,
        },
        inRoom: 0,
      },
      averageCoverBeverage: {
        allDay: {
          internal: 0,
          external: 0,
        },
        signature: {
          internal: 0,
          external: 0,
        },
        lounge: {
          internal: 0,
          external: 0,
        },
        poolSide: {
          internal: 0,
          external: 0,
        },
        inRoom: 0,
      },
      staffPerSeat:0,
      enabled :false,
      aliases : {
        "allDay": "All-Day Dining",
        "outlet1": "Signature Restaurant",
        "outlet2": "Lounge",
        "outlet3": "Pool side Restaurant"
    }
    },

    //STEP 6
    externalVisitors: {
      numberOfVisitors: otherRevArr,
      foodAndBeveragePerVisitor: otherRevArr,
      recreationPerVisitor: otherRevArr,
      spaPerVisitor: otherRevArr,
      otherOnePerVisitor: otherRevArr,
      otherTwoPerVisitor: otherRevArr,
    },

    //STEP 7
    economiesOfScale: "",
    benchmarkType: "",
    positioning: "",
    expenseLevel: "",

    departmentalExpenses: {
      rooms: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      foodAndBeverage: {
        payroll: payRollArr,
        costOfSales: costOfSaleArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      recreation: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      spa: {
        payroll: payRollArr,
        costOfSales: costOfSaleArr,

        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      otherProfitDepartments: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      retail: {
        payroll: payRollArr,
        costOfSales: costOfSaleArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      miscellaneous: {
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      rental: {
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
      },
      other1: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        otherOneLabel: "Other 1",
      },
      other2: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        otherTwoLabel: "Other 2",
      },
    },

    //STEP 8
    undistributedExpenses: {
      adminGeneral: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        creditCardCommissions: creditCardArr,
      },
      informationTelecom: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        // creditCardCommissions: creditCardArr,
      },
      salesMarketing: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        otherExpensesYearlyIncrement: otherExpensesIncrementsArr,
        // creditCardCommissions: creditCardArr,
      },
      propertyOperations: {
        payroll: payRollArr,
        payrollYearlyIncrement: payRollIncrementsArr,
        other: otherExpensesArr,
        otherPayrollYearlyIncrement: otherExpensesIncrementsArr,
        // creditCardCommissions: creditCardArr,
      },
      utilities: {
        // payRoll: payRollArr,
        // payrollYearlyIncrement: payRollIncrementsArr,
        otherPerKey: otherExpensesArr,
        payrollYearlyIncrement: otherExpensesIncrementsArr,
        // creditCardCommissions: creditCardArr,
      },
    },
    fixedIncome: "",
    operationalInsurance: "",
    permitsAndLicenses: "",
    landLeaseFee: "",
    otherFixedCharges1: "",
    propertyAndOtherTaxes: "",
    neomHotelsDevFee: "",
    //assetManagementFee: "",
    assetManagementFee: developmentData ? breakdown?.assetManagementFee : "",
    otherFixedCharges2: "",

    // STEP 9
    managmentBaseFee: developmentData ? baseFee : managmentBaseFee,
    managmentIncentiveFee: developmentData
      ? incentiveFee
      : managmentIncentiveFee,
    franchiseFee: [
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
    ],
    includeFranchiseFee: "yes",
    revenueType: "total",
    percentageCap: "",
    franchiseFeeLabel: "franshise fee",
    reservationContribution: [
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
    ],
    reservationContributionRevenueType: "total",
    reservationContributionLabel: "RESERVATION CONTRIBUTION",
    adminAndGeneral: [
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
      { fixedCharge: 0, percentage: 0 },
    ],
    adminAndGeneralRevenueType: "total",
    adminAndGeneralLabel: "ADMIN & GENERAL - OTHER",
    marketingContribution: "",
    marketingContributionField2: "",
    rewardProgram: "",
    roomBookings: "",
    operatorOtherProgramCost: "",
    technicalFeeToOperator: "",

    managmentFFEReserveFee: managmentFFEReserveFee,

    ffeReserveAddBack: "",
    leaseFixed: "",
    leaseVariable: "",
    staffPerKey: staffingArr,
    staffHousing: staffingArr,
    staffPhasing: "",
    workingCapital: "",

    // STEP 10
    unitMix: [unitMixGroup],
    annualSalesAbsorption: annualSalesAbsorption,
    Efficiency: "",
    opreatorContributions: "",
    adminExpenses: "",
    marketingSellingCost: "",
    residentialSaleFee: "",
    residentialConsultancyFee: "",
    adminCharges: "",
    shareOfResidence: "",
    minimumResidence: "",
    finalAssetSale: "",
    brandedResidence: "",
    minimumResidenceRadio: "",
    softCosts: "",
    preOpTraining: "",
    ffeAndOse: "",

    // STEP 11

    expenses1: "",
    expenses2: "",
    expenses3: "",
    expenses4: "",
    expenses5: "",
    brandedBaseFee: brandedBaseFee,
    brandedIncentiveFee: brandedIncentiveFee,
    brandedFranchiseFee: brandedFranchiseFee,
    /* brandedResidencesFields: {
      rentalPool: {
        type1SQM: "",
        type2SQM: "",
      },
      avgNumberOfDaysPerAnnum: {
        type1SQM: "",
        type2SQM: "",
      },
      stabilizedOccupancy: {
        type1SQM: "",
        type2SQM: "",
      },
      adrPerUnit: {
        type1SQM: "",
        type2SQM: "",
      },
      packageRate: {
        type1SQM: "",
        type2SQM: "",
      },
    }, */
    brandedResidencesFields: {
      rentalPool: [],
      avgNumberOfDaysPerAnnum: [],
      stabilizedOccupancy: [],
      adrPerUnit: [],
      otherRev: Array(10).fill(""),
    },

    // <----->
    //Final Step
    assignTo: "",
    addComment: "",
    approveAssetInfo: false,
    //-------------CALCULATION-----------
    calc1: 0,
    calc2: 0,
    calc3: 0,
    calc4: 0,
    calc5: 0,
    calc6: 0,

    calcTotalGfaBrandedRes: 0,
    calcTotalSellableGla: 0,
    calcTotalDevelopmentCostPerKey: 0,
    calcTotalNumberOfKeys: 0,
    calcTotalSalesPrice: 0,
    calcTotalBaseCost: 0,

    //calculation number 2
    weightedADR1: 0,
    weightedADR2: 0,
    weightedADR3: 0,
    weightedADR4: 0,
    weightedADR5: 0,
    weightedADR6: 0,
  };
};
/* export const assetsFormInitialValues = {
  //links: [linksGroup],
  //STEP 1
  projectName: "",
  projectNameValue: "",
  assetType: "Standard",
  regionLookup: "",
  operatorLookup: "",
  brandLookup: "",
  managementContractLookup: "",
  positioningLookup: "",
  projectStatusLookup: "",
  projectStageLookup: "",
  plotSize: "",
  grossFloorArea: "",
  parkingArea: "",
  assetBaseYear: "2023",
  numberOfKeys: "",
  startDateDevTime: "", //we need to split this into two val (year, montsh)
  developmentTimeDurationMonths: "",
  developmentTimeCompletionDate: "",
  startDateOpTime: "",
  operationTimeDurationMonths: "",
  operationTimeEndDate: "",

  developmentTimeStartYear: "",
  developmentTimeStartMonth: "",
  developmentTimeStartDate: "",
  operationStartYear: "",
  operationStartMonth: "",
  operationStartDate: "",

  //STEP 2
  developmentAbsorptionLand: developmentAbsorptionLand,
  refurbishmentCost: "",
  refurbishmentYear: "",
  devCostPerKey: "",
  landCost: "",
  infraCost: "",
  infraDepreciation: "",
  infraDevStartMonth: "",
  infraDevDuration: "",
  parkingCost: "",
  parkingDepreciation: "",
  //parkingDevDuration: "",
  bDownConsCost: "",
  bDownDepConsCost: "",
  bDownFfeOse: "",
  bDownDepFfeOse: "",
  bDownSoftCost: "",
  bDownDepSoftCost: "",
  bDownPreOpenTM: "",
  bDownDepPreOpenTM: "",
  bDownTsaFee: "",
  bDownDepTsaFee: "",
  // bDownITbudget: "",
  // bDownDepITbudget: "",
  bDownNeomDevFee: "",
  bDownDepNeomDevFee: "",
  bDownContCost: "",
  bDownDepContCost: "",
  bDownIniWorkCapital: "",
  baseCost: "",

  //STEP 3
  taxRate: "",
  openCashBalance: "",
  equity: "",
  annualInterestRate: "",
  debtTerm: "",
  gracePeriod: "",
  debtProcFees: "",
  securityFees: "",
  commitmentFees: "",
  sellingCosts: "",
  TaxSaleVal: "",
  riskFreeRate: "",
  equityRiskPrem: "",
  countryRiskPrem: "",
  unleveredBeta: "",
  manualInput: "",
  buffer: "",
  ebitdaMulti: "",
  useCalculation: "",

  //STEP 4
  roomType: [roomTypeGroup],
  occupancyIncRateYear: occupancyIncRateYear,
  pricingInflator: "",
  avgLenOfStay: "",
  guestPerOccRoom: "",
  //calcTotalNumbersKeys: 0,

  //STEP 5
  otherRevenuesPerformance: {
    foodAndBeverage: otherRevArr,
    meetingEvents: otherRevArr,
    banquet: otherRevArr,
    recreation: otherRevArr,
    spa: otherRevArr,
    otherProfitDepartments: otherRevArr,
    retail: otherRevArr,
    miscellaneous: otherRevArr,
    rental: otherRevArr,
    other1: otherRevArr,
    other2: otherRevArr,
  },
  //STEP 6
  economiesOfScale: "",
  benchmarkType: "",
  positioning: "",
  expenseLevel: "",

  departmentalExpenses: {
    rooms: {
      payroll: payRollArr,
      payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
    },
    foodAndBeverage: {
      payroll: payRollArr,
      costOfSales: costOfSaleArr,
      // payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
    },
    recreation: {
      payroll: payRollArr,
      // payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
    },
    spa: {
      payroll: payRollArr,
      costOfSales: costOfSaleArr,

      // payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
    },
    otherProfitDepartments: {
      payroll: payRollArr,
      // payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
    },
    retail: {
      payroll: payRollArr,
      costOfSales: costOfSaleArr,
      // payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
    },
    miscellaneous: {
      other: otherExpensesArr,
    },
    rental: {
      other: otherExpensesArr,
    },
    other1: {
      payroll: payRollArr,
      // payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
    },
    other2: {
      payroll: payRollArr,
      // payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
    },
  },

  //STEP 7
  undistributedExpenses: {
    adminGeneral: {
      payroll: payRollArr,
      payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
      creditCardCommissions: creditCardArr,
    },
    informationTelecom: {
      payroll: payRollArr,
      payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
      // creditCardCommissions: creditCardArr,
    },
    salesMarketing: {
      payroll: payRollArr,
      payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
      otherExpensesYearlyIncrement: otherExpensesIncrementsArr,
      // creditCardCommissions: creditCardArr,
    },
    propertyOperations: {
      payroll: payRollArr,
      payrollYearlyIncrement: payRollIncrementsArr,
      other: otherExpensesArr,
      otherPayrollYearlyIncrement: otherExpensesIncrementsArr,
      // creditCardCommissions: creditCardArr,
    },
    utilities: {
      // payRoll: payRollArr,
      // payrollYearlyIncrement: payRollIncrementsArr,
      otherPerKey: otherExpensesArr,
      payrollYearlyIncrement: otherExpensesIncrementsArr,
      // creditCardCommissions: creditCardArr,
    },
  },
  fixedIncome: "",
  operationalInsurance: "",
  permitsAndLicenses: "",
  landLeaseFee: "",
  otherFixedCharges1: "",
  propertyAndOtherTaxes: "",
  neomHotelsDevFee: "",
  assetManagementFee: "",
  otherFixedCharges2: "",

  // STEP 8
  managmentBaseFee: managmentBaseFee,
  managmentIncentiveFee: managmentIncentiveFee,
  franchiseFee: "",
  reservationContribution: "",
  adminAndGeneral: "",
  marketingContribution: "",
  marketingContributionField2: "",
  rewardProgram: "",
  roomBookings: "",
  operatorOtherProgramCost: "",
  technicalFeeToOperator: "",

  managmentFFEReserveFee: managmentFFEReserveFee,

  leaseFixed: "",
  leaseVariable: "",
  staffPerKey: "",
  staffHousing: "",
  staffPhasing: "",
  workingCapital: "",

  // STEP 9
  unitMix: [unitMixGroup],
  annualSalesAbsorption: annualSalesAbsorption,
  Efficiency: "",
  opreatorContributions: "",
  adminExpenses: "",
  marketingSellingCost: "",
  residentialSaleFee: "",
  residentialConsultancyFee: "",
  adminCharges: "",
  shareOfResidence: "",
  minimumResidence: "",
  finalAssetSale: "",
  brandedResidence: "",
  minimumResidenceRadio: "",
  softCosts: "",
  preOpTraining: "",
  ffeAndOse: "",

  // STEP 10

  expenses1: "",
  expenses2: "",
  expenses3: "",
  expenses4: "",
  expenses5: "",
  brandedBaseFee: brandedBaseFee,
  brandedIncentiveFee: brandedIncentiveFee,
  brandedFranchiseFee: brandedFranchiseFee,

  brandedResidencesFields: {
    rentalPool: [],
    avgNumberOfDaysPerAnnum: [],
    stabilizedOccupancy: [],
    adrPerUnit: [],
    otherRev: Array(10).fill(""),
  },

  // <----->
  //Final Step
  assignTo: "",
  addComment: "",
  approveAssetInfo: false,
  //-------------CALCULATION-----------
  calc1: 0,
  calc2: 0,
  calc3: 0,
  calc4: 0,
  calc5: 0,
  calc6: 0,

  calcTotalGfaBrandedRes: 0,
  calcTotalSellableGla: 0,
  calcTotalDevelopmentCostPerKey: 0,
  calcTotalNumberOfKeys: 0,
  calcTotalSalesPrice: 0,
  calcTotalBaseCost: 0,

  //calculation number 2
  weightedADR1: 0,
  weightedADR2: 0,
  weightedADR3: 0,
  weightedADR4: 0,
  weightedADR5: 0,
  weightedADR6: 0,
}; */
