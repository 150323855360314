import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import {
  AddAssetListAPI,
  GetAssetListByIdAPI,
  GetAssetListDraftByIdAPI,
  GetAssetRelatedFields,
  GetAssignedUsers,
  UpdateAssignedAssetListAPI,
  UpdateAssetListAPI,
  GetApprovalData
} from "../../api/AssetListAPI";
import {
  assetListStandardFormFormikValues,
  assetListPackageFormFormikValues,
  assetListProponentFormFormikValues,
  assetListStandardFormPreview,
  assetListPackageFormPreview,
  assetListProponentFormPreview,
} from "../../utils/AssetListObj";
import {
  assetListStepsArr,
  assetListDraftStepsArr,
} from "../../utils/AssetListSteps";

import {
  GetProductionOperatorWithIsActive,
  GetProductionPositioningWithIsActive,
  GetProductionMasterDataWithIsActive,
  GetProductionBrand,
  GetProductionContract,
  GetProductionStatus,
  GetProductionStage,
  GetAssetsUsers,
  GetProductionRoomType,
  GetProductionPackageType,
} from "../../api/MasterDataAPI";

import {
  format as dateFnsFormat,
  parseISO as dateFnsParseISO,
  set,
} from "date-fns";

import { Formik, Form } from "formik";
import {
  useDisclosure,
  Box,
  Text,
  HStack,
  Badge,
  Spacer,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { NavLink, useParams, useLocation } from "react-router-dom";

import { MainTitle } from "../../components/mainTitle/MainTitle";
import { MainBox } from "../../components/mainBox/MainBox";
import { ModalAlert } from "../../components/modalAlert/ModalAlert";
import { AssetStepper } from "../../components/assetStepper/AssetStepper";
import { ToastAlert } from "../../components/toastAlert/ToastAlert";
import history from "../../history";
import { AssetsListFormSteps } from "./AssetsListFormSteps";
import { validationAssetsForm } from "./validationAssetsForm";
import { validationAssetsFormPackage } from "./validationAssetsFormPackage";
import { assetsFormInitialValues } from "./assetsFormInitialValues";
import { assetsPackageFormInitialValues } from "./assetsPackageFormInitialValues";

import checked from "../../assets/images/icons/checked.svg";
import infoCircle from "../../assets/images/icons/infoCircle.svg";
import info from "../../assets/images/icons/Subtract.svg";
import { addMonths, parse, parseISO, formatISO, format } from "date-fns";

export const AssetsList = () => {
  const [user, setUser] = useState();

  let { type, id, draftId, projectNameValue, clone } = useParams();
  let location = useLocation();
  const isFromApproval = location.state?.isFromApproval; // Access state
  const toast = useToast();
  const dataFetchedRef = useRef(false);
  const draftBtnClickedRef = useRef(false);
  const [filteredDropdowns, setFilteredDropdowns] = useState([]);
  const [occIncRate, setOccIncRate] = useState(Array(6).fill(0));
  const [performanceAssetList2, setPerformanceAssetList2] = useState();
  const [brandedPerformance, setBrandedPerformance] = useState();
  const [packagePerformance, setPackagePerformance] = useState();
  const [unitMixChanged, setUnitMixChanged] = useState(false);
  const [packageRooms, setPackageRooms] = useState(false);
  const [warningIcon, setWarningIcon] = useState();
  const [dropdownObj, setDropdownObj] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [assetData, setAssetData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isDropdownEmpty, setIsDropdownEmpty] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [dismissSave, setDismissSave] = useState(true);
  const [page, setPage] = useState(0);
  const [validateErrors, setValidateErrors] = useState(true);
  const [assetBaseYear, setAssetBaseYear] = useState();
  const [devStartDate, setDevStartDate] = useState();
  const [opStartDate, setOpStartDate] = useState();
  const [isRestore, setIsRestore] = useState(false);
  const [missingPages, setMissingPages] = useState([]);
  const [operatorLookup, setOperatorLookup] = useState();
  const [assignedToOID, setAssignedToOID] = useState();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  //added
  const [isOnOff, setIsOnOff] = useState();
  const [updateDisableNextStep, setUpdateDisableNextStep] = useState();
  const [stepperUpdate, setStepperUpdate] = useState(false);
  const [positioningDropdown, setPositioningDropdown] = useState([]);
  const [associatedDropdown, setAssociatedDropdown] = useState();
  // const [brandDropdown, setBrandDropdown] = useState();
  const [validateStep, setValidateStep] = useState(false);
  const [modelWarningOpen, isModelWarningOpen] = useState(false);
  const [benchmarkDropdown, setBenchmarkDropdown] = useState({
    city: [],
    resort: [],
    serviced: [],
  });
  let cityOptions = [];
  let servicedOptions = [];
  let resortOptions = [];
  let operatorOptions2 = [];

  const {
    isOpen: isMissingOpen,
    onOpen: onMissingOpen,
    onClose: onMissingClose,
  } = useDisclosure();

  /*   let assetsFormObj =
    type === "Standard"
      ? assetsFormInitialValues
      : assetsPackageFormInitialValues; */
  const [initFormVal, setInitFormVal] = useState({});
  // const [firstInitFormVal, setFirstInitFormVal] = useState({});
  const [validateKeys, setValidateKeys] = useState(false);
  const [isDisabledCalc, setIsDisabledCalc] = useState(false);
  const [occIncRateGeneral, setOccIncRateGeneral] = useState([]);
  const [isCalculateClicked, setIsCalculateClicked] = useState(false);
  const [isDisabledTypes, setIsDisabledTypes] = useState({
    step: null,
    isStepDisabled: false,
  });

  let date;
  let lastMod;
  let submitModalTitle =
    type === "Standard"
      ? "Asset list standard is successfully created."
      : "Asset list package is successfully created.";
  let closeModalPath = projectNameValue
    ? `/AssetVersionHistory/${projectNameValue}`
    : `/AssetsHistory`;
  if (!!assetData?.lastModifiedAt) {
    date = dateFnsParseISO(assetData.lastModifiedAt);
    lastMod = dateFnsFormat(date, "dd.MM.yyyy; HH:mm");
  }

  let stepsArr = assetListStepsArr(type);

  const [steps, setSteps] = useState([]);

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const {
    isOpen: isSubmitOpen,
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
  } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();
  const {
    isOpen: isClickedFormulaOpen,
    onOpen: onClickedFormulaOpen,
    onClose: onClickedFormulaClose,
  } = useDisclosure();
  const {
    isOpen: isClickedKeysOpen,
    onOpen: onClickedKeysOpen,
    onClose: onClickedKeysClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const handleMissingPages = () => {
    setMissingPages([]);
    if (steps.find((e) => e.warning === true)) {
      steps.forEach((element, index) => {
        if (element.warning === true)
          setMissingPages((current) => [...current, `${element.title}, `]);
      });
    }
  };

  const handleProponentOnSubmit = (formikValue) => {
    if (!!id && !!draftId) {
      let commentsLogic =
        assetData.comments === formikValue.addComment
          ? ""
          : formikValue.addComment;

      let submitValues;
      if (type === "Standard") {
        submitValues = assetListProponentFormFormikValues(formikValue);
      } else {
        submitValues = assetListProponentFormFormikValues(formikValue);
      }
      submitValues = {
        ...submitValues,
        projectNameValue: projectNameValue,
        comments: commentsLogic,
      };

      UpdateAssetListAPI(id, draftId, submitValues, projectNameValue, type)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            onErrorOpen();

            setSubmitLoading(false);
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            setSubmitLoading(false);
          }
        })
        .catch((err) => {
          setSubmitLoading(false);
          console.log("err", err);
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    }
  };
  const handleOnSubmit = (formikValue, edit, isRestore) => {
    // console.log("---edit----", formikValue);
    if (edit === "assignedTo") {
      let submitValues = {
        assignedTo: formikValue.assignTo,
      };
      // console.log("submitValues just assign", submitValues);
      UpdateAssignedAssetListAPI(
        draftId || id,
        submitValues,
        type,
        projectNameValue
      )
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            onErrorOpen();

            setSubmitLoading(false);
            onSubmitClose();
          } else {
            if (res.success === true) {
              setDisableSave(true);
              setErrorMessage("");
              setSubmitLoading(false);
              // onSubmitOpen();
            }
          }
          return res;
        })
        .catch((err) => {
          setSubmitLoading(false);
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          onErrorOpen();
        });
    } else {
      if (!!id) {
        let commentsLogic =
          assetData.comments === formikValue.addComment
            ? ""
            : formikValue.addComment;

        let submitValues;
        if (type === "Standard") {
          submitValues = assetListStandardFormFormikValues(
            formikValue,
            user.oid
          );
        } else {
          submitValues = assetListPackageFormFormikValues(
            formikValue,
            user.oid
          );
        }
        const hasAssignee = !!formikValue.assignTo;
        submitValues = {
          ...submitValues,
          isDraft: false,
          isRestore: !!isRestore,
          comments: commentsLogic,
          parentVersionNumber:
            assetData.versionNumber || assetData.parentVersionNumber,
          isCreatingSharedDraft: hasAssignee,
          sharedDraftId: Number(draftId) || Number(id),
        };
        // console.log("submit values", submitValues);

        AddAssetListAPI(submitValues)
          .then((res) => {
            console.log("res", res);
            if (res?.status == 409) {
              setErrorMessage(
                "The production version has been updated, please perform your changes on the latest version"
              );
              onErrorOpen();

              setSubmitLoading(false);
              onSubmitClose();

              return { success: false };
            } else {
              if (res.success === true) {
                setDisableSave(true);
                setErrorMessage("");
                setSubmitLoading(false);
                // onSubmitOpen();
              }
              // res.status==409
              return res;
            }
          })
          .then((res) => {
            // console.log("res second API", res);
            if (res.success === true) {
              // console.log(
              //   "assignedTO------",
              //   submitValues.assignedTo,
              //   user.oid,
              //   assignedToOID
              // );
              if (
                submitValues.assignedTo !== "" &&
                submitValues.assignedTo != user.oid &&
                submitValues.assignedTo != assignedToOID
              ) {
                let reAssinedValues = {
                  assignedTo: submitValues.assignedTo,
                  assignedBy: user.oid,
                };
                // console.log(
                //   "submitValues just reAssinedValues",
                //   reAssinedValues
                // );
                UpdateAssignedAssetListAPI(
                  res.data.draftId || id,
                  reAssinedValues,
                  type,
                  projectNameValue
                )
                  .then((res) => {
                    if (res.success === true) {
                      // console.log("----REASSINED-----");
                      //setDisableSave(true);
                      //setErrorMessage("");
                      //onSubmitOpen();
                    }
                    return res;
                  })
                  .catch((err) => {
                    setSubmitLoading(false);
                    setErrorMessage(
                      err?.response?.data?.message || "Something went wrong"
                    );
                    onErrorOpen();
                  });
              }
            }
            return res;
          })
          .catch((err) => {
            setErrorMessage(
              err?.response?.data?.message || "Something went wrong"
            );
            onErrorOpen();
          });
      } else {
        let submitValues;
        if (type === "Standard") {
          submitValues = assetListStandardFormFormikValues(
            formikValue,
            user.oid
          );
        } else {
          submitValues = assetListPackageFormFormikValues(
            formikValue,
            user.oid
          );
        }
        const hasAssignee = !!formikValue.assignTo;

        submitValues = {
          ...submitValues,
          isDraft: false,
          isRestore: !!isRestore,
          //parentVersionNumber: -1,
          isCreatingSharedDraft: hasAssignee,
          sharedDraftId: -1,
        };

        // console.log("submitVal", submitValues);
        AddAssetListAPI(submitValues)
          .then((res) => {
            if (res.success === true) {
              setDisableSave(true);
              setErrorMessage("");
              setSubmitLoading(false);
              // onSubmitOpen();
            }
            return res;
          })

          .catch((err) => {
            setSubmitLoading(false);
            setErrorMessage(
              err?.response?.data?.message || "Something went wrong"
            );
            onErrorOpen();
          });
      }
    }
  };
  const handleSaveDraft = (formikValue) => {
    console.log("draaaaaaft", !!id, draftBtnClickedRef.current)
    if (draftBtnClickedRef.current) return;
    draftBtnClickedRef.current = true;

    if (!!id) {
      let commentsLogic =
        assetData.comments === formikValue.addComment
          ? ""
          : formikValue.addComment;

      let submitValues;
      if (type === "Standard") {
        submitValues = assetListStandardFormFormikValues(formikValue, user.oid);
      } else {
        submitValues = assetListPackageFormFormikValues(formikValue, user.oid);
      }
      submitValues = {
        ...submitValues,
        comments: commentsLogic,
        isDraft: true,
        //versionNumber: !!draftId ? Number(draftId) : 0,
        parentVersionNumber: Number(id),
      };

      // console.log(submitValues);
      AddAssetListAPI(submitValues)
        .then((res) => {
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            onErrorOpen();

            setSubmitLoading(false);
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onDraftClose();
            setSubmitLoading(false);

            onSubmitOpen();
          }
          return res.data;
        })
        .catch((err) => {
          setSubmitLoading(false);

          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          // onErrorOpen();
        });
    } else {
      let submitValues;
      if (type === "Standard") {
        submitValues = assetListStandardFormFormikValues(formikValue);
      } else {
        submitValues = assetListPackageFormFormikValues(formikValue);
      }
      submitValues = {
        ...submitValues,
        isDraft: true,
        parentVersionNumber: formikValue.versionNumber
      };
      // console.log("submit2", JSON.stringify(submitValues));
      AddAssetListAPI(submitValues)
        .then((res) => {
          //console.log("handleDraft", res);
          if (res?.status == 409) {
            setErrorMessage(
              "The production version has been updated, please perform your changes on the latest version"
            );
            onErrorOpen();

            setSubmitLoading(false);
            onSubmitClose();
          } else if (res.success === true) {
            setDisableSave(true);
            setErrorMessage("");
            onDraftClose();
            setSubmitLoading(false);

            onSubmitOpen();
          }
          return res.data;
        })
        .catch((err) => {
          setSubmitLoading(false);
          // console.log("handleDraft err", err);
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          // onErrorOpen();
        });
    }
  };
  useEffect(() => {
    // console.log("benchmarkDropdown", benchmarkDropdown, assetData);
    if (!!assetData && assetData?.formFields) {
      if (
        benchmarkDropdown?.serviced?.length > 0 ||
        benchmarkDropdown?.city?.length > 0 ||
        benchmarkDropdown?.resort?.length > 0
      ) {
        if (
          assetData.formFields.departmentalExpenses.benchmarkType ===
          "Serviced Apartments"
        ) {
          setAssociatedDropdown(benchmarkDropdown.serviced);
        }
        if (
          assetData.formFields.departmentalExpenses.benchmarkType ===
          "City benchmark"
        ) {
          setAssociatedDropdown(benchmarkDropdown.city);
        }

        if (
          assetData.formFields.departmentalExpenses.benchmarkType ===
          "Resort benchmark"
        ) {
          setAssociatedDropdown(benchmarkDropdown.resort);
        }
      }
    }
  }, [benchmarkDropdown, assetData]);

  const getData = () => {
    let stepsArr2 = [];
    let userVar = "";
    if (Cookies.get("user_info")) {
      let token = Cookies.get("user_info");
      //console.log("user_info", token);
      let tokenDecode = jwt_decode(token);
      setUser(tokenDecode);
      //setUser({ ...tokenDecode, roles: ["proponent"] });
      userVar = jwt_decode(token);

      // console.log("type 3", type, userVar?.roles[0]);
      //if (userVar?.roles[0] !== "admin") {
      stepsArr2 = assetListStepsArr(type, userVar?.roles[0]);
      setSteps(stepsArr2);
      //}
    }
    setErrorMessage("");
    GetAssetListByIdAPI(projectNameValue, id, isFromApproval)
      .then((res) => {
        if (clone) {
          res.data.versionNumber = 0
          res.parentVersionNumber = 0
          res.data.projectName += ' cloned'
          res.data.projectNameValue = ""
        }

        let submitValues;
        if (type === "Standard") {
          submitValues = assetListStandardFormPreview(res.data);
        } else {
          submitValues = assetListPackageFormPreview(res.data);
        }
        if (userVar?.roles[0] === "proponent") {
          handleProponentSelects(res.data);
        }
        // console.log("submit values", submitValues);
        setOccIncRate([...submitValues.occupancyIncRateYear]);
        setInitFormVal({
          ...submitValues,
        });
        // setFirstInitFormVal({
        //   ...submitValues,
        // });

        //console.log(JSON.stringify(submitValues));
        setAssetData(res.data);
        //console.log("res.data", res.data);
        if (type === "Standard") {
          setPerformanceAssetList2(
            res.data.topLineAssumptions_Standard.roomTypes.length
          );
        } else {
          setPackagePerformance(
            res.data.topLineAssumptions_Package.packageTypes.length
          );
        }
        setBrandedPerformance(
          res.data.brandedResidencesPerformance.includedInRentalPool.length
        );
        setDevStartDate(res.data.developmentTimeStartYear);
        setOpStartDate(res.data.operationStartYear);
        setOperatorLookup(res.data.operatorLookup);
        setAssignedToOID(res.data.assignedTo);
        setAssociatedDropdown(
          res.data.departmentalExpenses.benchmarkType === "City benchmark"
            ? cityOptions
            : res.data.departmentalExpenses.benchmarkType ===
              "Resort benchmark"
              ? resortOptions
              : res.data.departmentalExpenses.benchmarkType ===
                "Serviced Apartments"
                ? servicedOptions
                : []
        );

        setIsOnOff(res.data.brandedResidencesGeneral.isOnOff);
      })

      .catch((err) => {
        console.log(err);
        setErrorMessage("Something went wrong");
        onErrorOpen();
      });

    stepsArr2.forEach((element) => {
      element.clicked = true;
    });

    stepsArr2.forEach((element) => {
      element.completed = true;
    });
    setPercentage(100);
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (location?.state?.from == "Restore") {
      setIsRestore(true);
    }
    getDropdowns();

    let stepsArr2 = [];
    let userVar = "";
    if (Cookies.get("user_info")) {
      let token = Cookies.get("user_info");
      //console.log("user_info", token);
      let tokenDecode = jwt_decode(token);
      setUser(tokenDecode);
      //setUser({ ...tokenDecode, roles: ["proponent"] });
      userVar = jwt_decode(token);

      // console.log("type 3", type, userVar?.roles[0]);
      //if (userVar?.roles[0] !== "admin") {
      stepsArr2 = assetListStepsArr(type, userVar?.roles[0]);
      setSteps(stepsArr2);
      //}
    }
    if (!!id && clone) {
      getData()
    } else if (!!id && !!draftId) {
      setErrorMessage("");
      GetAssetListDraftByIdAPI(projectNameValue, id, draftId)
        .then((res) => {
          // console.log("assetData 2", res.data, benchmarkDropdown);
          let submitValues;
          if (type === "Standard") {
            submitValues = assetListStandardFormPreview(res.data);
          } else {
            submitValues = assetListPackageFormPreview(res.data);
          }
          if (userVar?.roles[0] === "proponent") {
            handleProponentSelects(res.data, true);
          }

          // console.log("submit values", submitValues);
          let stepsArr = assetListDraftStepsArr(
            type,
            res.data,
            userVar?.roles[0]
          );
          // console.log("stepsArr Draft", stepsArr);

          setSteps(stepsArr);
          setOccIncRate([...submitValues.occupancyIncRateYear]);
          setInitFormVal({
            ...submitValues,
          });
          // setFirstInitFormVal({
          //   ...submitValues,
          // });
          setAssetData(res.data);

          if (location?.state?.from == "Reassign") {
            //setIsReassign(true);
            if (type === "Standard") {
              setTimeout(() => {
                setPage(10);
              }, 500);
            } else {
              setTimeout(() => {
                setPage(11);
              }, 500);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage("Something went wrong");
          onErrorOpen();
        });
    } else if (!!id) {
      getData()
    } else {
      let assetsFormObj;
      GetAssetRelatedFields()
        .then((res) => {
          // console.log("res.success", res, res.success);
          if (res.success) {
            if (
              !!res.data.data.benchmarkHotelKPIs &&
              !!res.data.data.benchmarkDevelopmentCosts
            ) {
              assetsFormObj =
                type === "Standard"
                  ? assetsFormInitialValues(
                    JSON.parse(res.data.data.benchmarkHotelKPIs.formFields),
                    JSON.parse(
                      res.data.data.benchmarkDevelopmentCosts.formFields
                    )
                  )
                  : assetsPackageFormInitialValues(
                    JSON.parse(res.data.data.benchmarkHotelKPIs.formFields),
                    JSON.parse(
                      res.data.data.benchmarkDevelopmentCosts.formFields
                    )
                  );

              const isPreProduction = location.state?.isPreProduction; // Access state
              if (isPreProduction) {
                const versionNumber = location.state?.versionNumber; // Access state
                const projectNameValue = location.state?.projectNameValue; // Access state
                GetApprovalData(projectNameValue, versionNumber).then((res) => {
                  let data = res.data
                  let {projectNameValue, projectName, regionLookup, operatorLookup, brandLookup, positioningLookup, assetType, numberOfKeys,
                     brandedResidencesGeneral, calcTotalNumberOfKeys, developmentTimeStartDate, developmentTimeDurationMonths, operationStartDate, operationTimeDurationMonths} = data
                  const assetsFormObjData = {};
                  assetsFormObj.projectNameValue = projectNameValue;
                  assetsFormObj.projectName = projectName;
                  assetsFormObj.regionLookup = regionLookup;
                  assetsFormObj.operatorLookup = operatorLookup;
                  assetsFormObj.brandLookup = brandLookup;
                  assetsFormObj.positioningLookup = positioningLookup;
                  assetsFormObj.assetType = assetType;
                  assetsFormObj.numberOfKeys = numberOfKeys
                  assetsFormObj.brandedResidence = data.brandedResidencesGeneral == null ?"": brandedResidencesGeneral.brandedResidencesInclusion == true ? "1"
                    : brandedResidencesGeneral.brandedResidencesInclusion == false
                      ? "2"
                      : ""

                    assetsFormObj.calcTotalNumberOfKeys = calcTotalNumberOfKeys

                  let newStartDateDevTime = developmentTimeStartDate
                    ? parseISO(developmentTimeStartDate)
                    : "";
                  let newStartDateOpTime = operationStartDate
                    ? parseISO(operationStartDate)
                    : "";

                  let newDevelopmentTimeCompletionDate =
                    newStartDateDevTime &&
                      (developmentTimeDurationMonths ||
                        developmentTimeDurationMonths === 0)
                      ? format(
                        addMonths(
                          newStartDateDevTime,
                          developmentTimeDurationMonths
                        ),
                        "MM/yyyy"
                      )
                      : "";
                  let newOperationTimeEndDate =
                    newStartDateOpTime &&
                      (operationTimeDurationMonths ||
                        operationTimeDurationMonths === 0)
                      ? format(
                        addMonths(newStartDateOpTime, operationTimeDurationMonths),
                        "MM/yyyy"
                      )
                      : "";

                  assetsFormObj.startDateDevTime = newStartDateDevTime
                  assetsFormObj.developmentTimeCompletionDate = newDevelopmentTimeCompletionDate
                  assetsFormObj.operationTimeEndDate = newOperationTimeEndDate
                  assetsFormObj.startDateOpTime = newStartDateOpTime
             
                setInitFormVal(assetsFormObj);
                }
                );

              } else

                setInitFormVal(assetsFormObj);
            } else {
              // console.log("hotelKpis", res.data);
              assetsFormObj =
                type === "Standard"
                  ? assetsFormInitialValues(null, null)
                  : assetsPackageFormInitialValues(null, null);
              // console.log("assetsFormObj", assetsFormObj);
              setInitFormVal(assetsFormObj);
            }
          } else {
            assetsFormObj =
              type === "Standard"
                ? assetsFormInitialValues(null, null)
                : assetsPackageFormInitialValues(null, null);
            setInitFormVal(assetsFormObj);
          }
          // setFirstInitFormVal({
          //   ...assetsFormObj,
          // });
        })
        .catch((err) => {
          setErrorMessage(
            err?.response?.data?.message || "Something went wrong"
          );
          // onErrorOpen();
        });
    }
  }, []);
  useEffect(() => {
    let unblock;
    if (!disableSave && dismissSave) {
      unblock = history.block((tx) => {
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to discard the changes?`)) {
          unblock();

          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [disableSave, dismissSave]);

  const getUsers = (regionLookup) => {
    GetAssignedUsers(regionLookup)
      .then((res) => {
        if (res.data.length > 0) {
          let assigndUsersArr = [];
          if (res.data?.length > 0) {
            res.data.forEach((item) => {
              assigndUsersArr.push({
                value: item?.oid,
                label: item?.displayName,
              });
            });
            setDropdownObj({
              ...dropdownObj,
              users: assigndUsersArr,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let regionOptions = [];
  let operatorOptions = [];
  let brandOptions = [];
  let positioningOptions = [];
  let contractOptions = [];
  let statusOptions = [];
  let stageOptions = [];
  let roomTypesOptions = [];
  let packageTypesOptions = [];
  let assetsUsers = [];
  let roomTypesAssciatedWith = [];
  const getDropdowns = () => {
    let getProdRegion = new Promise((resolve, reject) => {
      GetProductionMasterDataWithIsActive()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                regionOptions.push({
                  value: item.value,
                  label: item.label,
                });
              });

              const index = filteredDropdowns?.indexOf("Region");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }
              resolve(regionOptions);
            }
          } else {
            setFilteredDropdowns((prevState) => ["Regions", ...prevState]);
            setIsDropdownEmpty(true);
          }
        })

        .catch((err) => {
          reject(err);
          console.log(err);
          setErrorMessage("Something went wrong");
          setFilteredDropdowns((prevState) => ["Regions", ...prevState]);
        });
    });

    //ROOM TYPES
    let getProdRoomTypes = new Promise((resolve, reject) => {
      GetProductionRoomType()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                let tempLabel = `${item.associatedWith[0]} - ` + item.label;
                roomTypesOptions.push({
                  value: item.value,
                  label: tempLabel,
                });
                roomTypesAssciatedWith.push(item);
              });

              const index = filteredDropdowns?.indexOf("Room type");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }
              resolve(roomTypesOptions);
            }
          } else {
            setFilteredDropdowns((prevState) => [...prevState, "Room type"]);
            setIsDropdownEmpty(true);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          setErrorMessage("Something went wrong");
          // onErrorOpen();
          setFilteredDropdowns((prevState) => [...prevState, "Room type"]);
        });
    });

    //PACKAGE TYPE
    let getProdPackageType = new Promise((resolve, reject) => {
      GetProductionPackageType()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                packageTypesOptions.push({
                  value: item.value,
                  label: item.label,
                });
              });

              const index = filteredDropdowns?.indexOf("Package type");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }
              resolve(packageTypesOptions);
            }
          } else {
            setFilteredDropdowns((prevState) => [...prevState, "Package type"]);
            setIsDropdownEmpty(true);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          setErrorMessage("Something went wrong");
          // onErrorOpen();
          setFilteredDropdowns((prevState) => [...prevState, "Package type"]);
        });
    });
    //OPERATOR
    let getProdOperator = new Promise((resolve, reject) => {
      GetProductionOperatorWithIsActive()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                operatorOptions.push({
                  value: item.value,
                  label: item.label,
                });
              });

              const index = filteredDropdowns?.indexOf("Operator");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }
              resolve(operatorOptions);
            }

            //added
            res.data[0].lookups.forEach((item) => {
              var tempObj = {};
              tempObj[item.label] = [];
              operatorOptions2.push(tempObj);
            });
          } else {
            setFilteredDropdowns((prevState) => [...prevState, "Operator"]);
            setIsDropdownEmpty(true);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          setErrorMessage("Something went wrong");
          // onErrorOpen();
          setFilteredDropdowns((prevState) => [...prevState, "Operator"]);
        });
    });
    //POSITIONING
    let getProdPositioning = new Promise((resolve, reject) => {
      GetProductionPositioningWithIsActive()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                positioningOptions.push({
                  value: item.value,
                  label: item.label,
                });
              });

              const index = filteredDropdowns?.indexOf("Positioning");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }
              resolve(positioningOptions);

              //added

              cityOptions = res.data[0].lookups.filter((el) =>
                el.associatedWith.includes("City benchmarks")
              );
              cityOptions = cityOptions.map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                };
              });
              resortOptions = res.data[0].lookups.filter((el) =>
                el.associatedWith.includes("Resort benchmarks")
              );
              resortOptions = resortOptions.map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                };
              });
              // resortOptions.forEach((item) => {
              //   positioningDropdown.push({
              //     value: item.value,
              //     label: item.label,
              //   });
              // });

              servicedOptions = res.data[0].lookups.filter((el) =>
                el.associatedWith.includes("Serviced Apartments")
              );
              servicedOptions = servicedOptions.map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                };
              });
              // servicedOptions.forEach((item) => {
              //   positioningDropdown.push({
              //     value: item.value,
              //     label: item.label,
              //   });
              // });
              setBenchmarkDropdown({
                city: cityOptions,
                resort: resortOptions,
                serviced: servicedOptions,
              });
            }
          } else {
            setFilteredDropdowns((prevState) => [...prevState, "Positioning"]);
            setIsDropdownEmpty(true);
          }
        })
        .catch((err) => {
          reject(err);
          console.log(err);
          setErrorMessage("Something went wrong");
          setFilteredDropdowns((prevState) => [...prevState, "Positioning"]);

          // onErrorOpen();
        });
    });

    //BRAND
    let tempBrandArr = [];
    let getProdBrand = new Promise((resolve, reject) => {
      GetProductionBrand()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                brandOptions.push({
                  value: item.value,
                  label: item.label,
                });
                tempBrandArr.push({
                  value: item.value,
                  label: item.label,
                  associatedWith: item.associatedWith,
                });
              });

              const index = filteredDropdowns?.indexOf("Brand");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }
              // setBrandDropdown(brandOptions);
              resolve(tempBrandArr);
            }
          } else {
            setFilteredDropdowns((prevState) => [...prevState, "Brand"]);
            setIsDropdownEmpty(true);
          }
        })
        .catch((err) => {
          reject(err);
          console.log(err);
          setErrorMessage("Something went wrong");
          setFilteredDropdowns((prevState) => [...prevState, "Brand"]);

          // onErrorOpen();
        });
    });
    //CONTRACT

    let getProdContract = new Promise((resolve, reject) => {
      GetProductionContract()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                contractOptions.push({
                  value: item.value,
                  label: item.label,
                });
              });

              const index = filteredDropdowns?.indexOf("Contract");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }
              resolve(contractOptions);
            }
          } else {
            setFilteredDropdowns((prevState) => [...prevState, "Contract"]);
            setIsDropdownEmpty(true);
          }
        })
        .catch((err) => {
          reject(err);
          console.log(err);
          setErrorMessage("Something went wrong");
          setFilteredDropdowns((prevState) => [...prevState, "Contract"]);

          // onErrorOpen();
        });
    });
    //STATUS
    let getProdProjStatus = new Promise((resolve, reject) => {
      GetProductionStatus()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                statusOptions.push({
                  value: item.value,
                  label: item.label,
                });
              });

              const index = filteredDropdowns?.indexOf("Status");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }
              resolve(statusOptions);
            }
          } else {
            setFilteredDropdowns((prevState) => [
              ...prevState,
              "Project status",
            ]);
            setIsDropdownEmpty(true);
          }
        })
        .catch((err) => {
          reject(err);
          console.log(err);
          setErrorMessage("Something went wrong");
          setFilteredDropdowns((prevState) => [...prevState, "Project status"]);

          // onErrorOpen();
        });
    });
    //STAGE
    let getProdStage = new Promise((resolve, reject) => {
      GetProductionStage()
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0]?.lookups?.length > 0) {
              res.data[0].lookups.forEach((item) => {
                stageOptions.push({
                  value: item.value,
                  label: item.label,
                });
              });
              const index = filteredDropdowns?.indexOf("Stage");
              if (index > -1) {
                filteredDropdowns.splice(index, 1);
              }

              resolve(stageOptions);
            }
          } else {
            setFilteredDropdowns((prevState) => [
              ...prevState,
              "Project stage",
            ]);
            setIsDropdownEmpty(true);
          }
        })
        .catch((err) => {
          reject(err);
          console.log(err);
          setErrorMessage("Something went wrong");
          setFilteredDropdowns((prevState) => [...prevState, "Project stage"]);

          // onErrorOpen();
        });
    });

    Promise.all([
      getProdRegion,
      getProdOperator,
      getProdPositioning,
      getProdBrand,
      getProdContract,
      getProdProjStatus,
      getProdStage,
      getProdRoomTypes,
      getProdPackageType,
      // getAssignedUsersList,
    ])
      .then((values) => {
        // console.log("vaaaaaal drop", values);

        setDropdownObj({
          region: values[0],
          position: [
            { value: "iconic", label: "Iconic" },
            { value: "ultraLuxury", label: "Ultra Luxury" },
            { value: "luxury", label: "Luxury" },
            { value: "uppedUpscale", label: "Upper Upscale" },
            { value: "upscale", label: "Upscale" },
            { value: "midscale", label: "Midscale" },
            { value: "deluxeApartment", label: "Deluxe Serviced Apartment" },
            {
              value: "standardApartment",
              label: "Standard Serviced Apartment",
            },
            { value: "lodges", label: "Lodges" },
            { value: "glamping", label: "Glamping" },
          ],
          devDuration: [
            { value: "any1", label: "any 1" },
            { value: "any1", label: "any 2" },
          ],
          monthsOperations: [
            { value: "any2", label: "any 1" },
            { value: "any2", label: "any 2" },
          ],
          contractType: [
            { value: "hma", label: "HMA" },
            { value: "nhd", label: "NHD Operated (NEOM Brand)" },
            { value: "franchise", label: "Franchise" },
            { value: "manchise", label: "Manchise" },
            { value: "unbranded", label: "Unbranded" },
            { value: "lease", label: "Lease" },
          ],
          managementContract: values[4],
          projectStage: values[6],
          timelineStatus: [
            { value: "On Total Revenue", label: "On Total Revenue" },
            { value: "On Room Revenue", label: "On Room Revenue" },
          ],
          revenueStatus: [
            { value: "total", label: "Total revenue" },
            { value: "rooms", label: "Rooms revenue " }
          ],
          realisation: [
            { value: "Unlikely", label: "Unlikely" },
            { value: "Likely", label: "Likely" },
            { value: "Certain", label: "Certain" },
            { value: "Remove from List", label: "Remove from List" },
          ],
          numberOfMonths: [
            { value: "January", label: "1- January" },
            { value: "February", label: "2- February" },
            { value: "March", label: "3- March" },
            { value: "April", label: "4- April" },
            { value: "May", label: "5- May" },
            { value: "June", label: "6- June" },
            { value: "July", label: "7- July" },
            { value: "August", label: "8- August" },
            { value: "September", label: "9- September" },
            { value: "October", label: "10- October" },
            { value: "November", label: "11- November" },
            { value: "December", label: "12- December" },
          ],
          /* users:
            assetsUsers.length === 0
              ? [
                  { value: "stephanSmith", label: "Stephan Smith" },
                  { value: "sarahWhitmore", label: "Sarah Whitmore" },
                  { value: "abdelFattahAhmad", label: "Abdel Fattah Ahmad" },
                ]
              : assetsUsers, */
          // users: values[9],
          types: values[7],
          packageTypes: values[8],
          operator: values[1],
          benchmarkTypes: [
            { value: "City benchmark", label: "City benchmark " },
            { value: "Resort benchmark", label: "Resort benchmark" },
            { value: "Serviced Apartments", label: "Serviced Apartments" },
          ],
          brand: values[3],
          positioning: values[2],
          projectStatus: values[5],
          expenseLevel: [
            { value: "Low", label: "Low " },
            { value: "High", label: "High" },
            { value: "Average", label: "Average" },
          ],
          roomTypesAssociatedWith: roomTypesAssciatedWith,
          infraDevStartMonth: [{ value: "1", label: "1" }],
        });
      })
      .catch((err) => console.log(err));
  };
  const closeToast = () => {
    toast.closeAll();
  };

  const handleProponentSelects = (data, isDraft) => {
    if (!isDraft)
      setDropdownObj({
        region: [
          {
            value: data.regionLookup,
            label: data.regionLookup.replaceAll("_", " "),
          },
        ],
        operator: [
          {
            value: data.operatorLookup,
            label: data.operatorLookup.replaceAll("_", " "),
          },
        ],
        brand: [
          {
            value: data.brandLookup,
            label: data.brandLookup.replaceAll("_", " "),
          },
        ],
        projectStatus: [
          {
            value: data.projectStatusLookup,
            label: data.projectStatusLookup.replaceAll("_", " "),
          },
        ],
        projectStage: [
          {
            value: data.projectStageLookup,
            label: data.projectStageLookup.replaceAll("_", " "),
          },
        ],
        positioning: [
          {
            value: data.positioningLookup,
            label: data.positioningLookup.replaceAll("_", " "),
          },
        ],
        managementContract: [
          {
            value: data.managementContractLookup,
            label: data.managementContractLookup.replaceAll("_", " "),
          },
        ],
      });
    else {
      setDropdownObj({
        region: [
          {
            value: data.regionLookup,
            label: data.regionLookup.replaceAll("_", " "),
          },
        ],
        operator: [
          {
            value: data.formFields.operatorLookup,
            label: data.formFields.operatorLookup.replaceAll("_", " "),
          },
        ],
        brand: [
          {
            value: data.formFields.brandLookup,
            label: data.formFields.brandLookup.replaceAll("_", " "),
          },
        ],
        projectStatus: [
          {
            value: data.formFields.projectStatusLookup,
            label: data.formFields.projectStatusLookup.replaceAll("_", " "),
          },
        ],
        projectStage: [
          {
            value: data.formFields.projectStageLookup,
            label: data.formFields.projectStageLookup.replaceAll("_", " "),
          },
        ],
        positioning: [
          {
            value: data.formFields.positioningLookup,
            label: data.formFields.positioningLookup.replaceAll("_", " "),
          },
        ],
        managementContract: [
          {
            value: data.formFields.managementContractLookup,
            label: data.formFields.managementContractLookup.replaceAll(
              "_",
              " "
            ),
          },
        ],
      });
    }
  };
  useEffect(() => {
    setValidateStep(false);
  }, [page]);
  useEffect(() => {
    if (isOnOff === false) {
      if (type === "Standard") {
        unsetPageClicked(11);
        setUpdateDisableNextStep(true);
      } else {
        unsetPageClicked(11);
        setUpdateDisableNextStep(true);
      }
    }
  }, [isOnOff]);
  useEffect(() => {
    if (modelWarningOpen) {
      toast({
        position: "top-right",
        isClosable: true,
        render: () => (
          <ToastAlert
            title="You must fill all the fields."
            icon={infoCircle}
            closeToast={closeToast}
            type="Warning"
          />
        ),
      });
    }
    isModelWarningOpen(false);
  }, [modelWarningOpen]);

  const unsetPageClicked = (page) => {
    for (const obj of steps) {
      if (obj.id === page + 1) {
        obj.clicked = false;
        obj.completed = false;
        obj.warning = false;
        break;
      }
    }
  };

  const undoUnsetPageClicked = (page) => {
    for (const obj of steps) {
      if (obj.id === page + 1) {
        obj.clicked = true;
        break;
      }
    }
  };
  const nextPage = (number) => {
    if (number) {
      setPage(page + number);
    } else setPage(page + 1);
  };

  const prevPage = (number) => {
    if (number) {
      setPage(page - number);
    } else setPage(page - 1);
  };
  const setCurrentPage = (id) => {
    setPage(id);
    for (const obj of steps) {
      if (obj.id === page + 1) {
        obj.clicked = true;

        break;
      }
    }
  };
  const unsetStepWarning = (page) => {
    for (const obj of steps) {
      if (obj.id === page + 1) {
        obj.warning = false;

        break;
      }
    }
  };
  const setStepWarning = (page) => {
    for (const obj of steps) {
      if (obj.id === page + 1 && obj.completed === true) {
        obj.warning = true;

        break;
      }
    }
    for (const obj of steps) {
      if (obj.id === page + 1) {
        obj.completed = false;

        break;
      }
    }
  };
  const setStepCompleted = (page) => {
    // const stepIndex = (steps.findIndex(obj => obj.title === event.target.children[0].innerHTML))
    // console.log(stepIndex)
    // // steps[steps.findIndex(obj => obj.title === event.target.children[0].innerHTML)].completed = true
    // const arr = [...steps, steps[stepIndex].completed = true]

    for (const obj of steps) {
      if (obj.id === page + 1) {
        obj.completed = true;

        break;
      }
    }

    for (const obj of steps) {
      if (obj.id === page + 1) {
        obj.clicked = true;

        break;
      }
    }
  };

  return (
    <div>
      <>
        {id === undefined ? (
          <MainTitle title="Create new asset" />
        ) :
          clone === undefined ?
            (<MainTitle title="Edit new asset " />
            ) : (<MainTitle title="Clone asset" />)}

        <Box display="flex" className="table_content" alignItems="flex-start">
          {!isDropdownEmpty || user?.roles[0]?.toLowerCase() === "proponent" ? (
            <>
              <AssetStepper
                page={page}
                steps={steps}
                setCurrentPage={setCurrentPage}
                percentage={percentage}
                setStepCompleted={setStepCompleted}
                title={
                  type === "Standard"
                    ? "Standard offering asset"
                    : "Package Offering asset"
                }
                type={type}
                isDisabledCalc={isDisabledCalc}
                isDisabledTypes={isDisabledTypes}
                validateKeys={validateKeys}
                onClickedFormulaOpen={onClickedFormulaOpen}
                onClickedKeysOpen={onClickedKeysOpen}
                setStepWarning={setStepWarning}
                user={user?.roles[0].toLowerCase()}
              />
              <Spacer minWidth="25px" />

              <MainBox maxWidth="100%">
                {/* <Box display="flex">
                <Text className="title_md" display="flex" component="div">
                  STEP
                </Text>
                <Text ml={1}>
                  {page + 1}/{steps.length}
                </Text>

                <Spacer />
                <HStack
                  px="2"
                  py="1"
                  borderRadius="5"
                  fontSize="12"
                  bg="rgba(0, 107, 68, 0.2);"
                >
                  <Image src={eye}></Image>
                  <Text>View Only</Text>
                </HStack>
              </Box>
              <Badge
                mb="5"
                border="1px solid rgba(241, 136, 37, 0.6)"
                bg="rgba(241, 136, 37, 0.1);"
                w="100%"
                px="4 !important"
                py="4 !important"
                mt="8"
                className="masterDataBadge"
              >
                <HStack gap="3">
                  <Image src={Personas}></Image>
                  <VStack w="100%" fontSize="13" alignItems="left">
                    <HStack>
                      <Text>Peter Griffin</Text>
                      <Spacer />
                      <Text fontSize="12" fontWeight="400" px="2">
                        19.07.2022; 11:50
                      </Text>
                    </HStack>
                    <Text>
                      Initial version created. Request Sarah to add asset data.
                    </Text>
                  </VStack>
                </HStack>
              </Badge> */}
                {id && clone == undefined && (
                  <Box>
                    <HStack
                      w="100%"
                      /* px="8" */ marginBottom={4}
                      marginTop={2}
                    >
                      <Badge
                        bg="rgba(0, 123, 181, 0.2)"
                        className="masterDataBadge"
                        marginLeft="auto"
                      >
                        Version Number:{" "}
                        {assetData?.status === "Draft" ||
                          assetData?.status === "InitialDraft" ||
                          assetData?.status === "Open" ||
                          assetData?.status === "InReview"
                          ? assetData?.parentVersionNumber
                          : assetData?.versionNumber}
                      </Badge>
                      <Badge
                        bg="rgba(241, 136, 37, 0.3)"
                        className="masterDataBadge"
                      >
                        Last Modified By: {assetData?.lastModifiedBy}, {lastMod}
                      </Badge>

                      {/* IF NOT SARA SHOW VIEW ONLY */}
                      <Spacer />
                      {/* <HStack
                  px="2"
                  py="1"
                  borderRadius="5"
                  fontSize="12"
                  bg="rgba(0, 107, 68, 0.2);"
                >
                  <Image src={eye}></Image>
                  <Text>View Only</Text>
                </HStack> */}
                    </HStack>
                  </Box>
                )}
                <Formik
                  enableReinitialize={true}
                  initialValues={initFormVal}
                  validationSchema={
                    type === "Standard"
                      ? validationAssetsForm[page]
                      : validationAssetsFormPackage[page]
                  }
                  validate={(values) => {
                    let newFormikValues = { ...initFormVal };
                    let newValues = { ...values };
                    if (newFormikValues?.developmentTimeCompletionDate)
                      delete newFormikValues.developmentTimeCompletionDate;
                    if (newValues?.developmentTimeCompletionDate)
                      delete newValues.developmentTimeCompletionDate;
                    if (newFormikValues?.operationTimeEndDate)
                      delete newFormikValues.operationTimeEndDate;
                    if (newValues?.operationTimeEndDate)
                      delete newValues.operationTimeEndDate;
                    if (
                      JSON.stringify(newValues) !=
                      JSON.stringify(newFormikValues) &&
                      JSON.stringify(values) !=
                      `{"developmentTimeCompletionDate":"-",operationTimeEndDate:"NaN/NaN"}` &&
                      JSON.stringify(values) !=
                      `{"developmentTimeCompletionDate":"NaN/NaN",operationTimeEndDate:"-"}` &&
                      JSON.stringify(newValues) != "{}"
                    ) {
                      if (disableSave) {
                        setDisableSave(false);
                      }
                    }
                  }}
                >
                  {({
                    isSubmitting,
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <Form className="form_wrapper">
                      <AssetsListFormSteps
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        type={type}
                        page={page}
                        dropdownObj={dropdownObj}
                        nextPage={nextPage}
                        prevPage={prevPage}
                        onCancelOpen={onCancelOpen}
                        onSubmitOpen={onSubmitOpen}
                        validateErrors={validateErrors}
                        setValidateErrors={setValidateErrors}
                        setPercentage={setPercentage}
                        setStepCompleted={setStepCompleted}
                        setStepWarning={setStepWarning}
                        unsetStepWarning={unsetStepWarning}
                        percentage={percentage}
                        dismissSave={dismissSave}
                        setDismissSave={setDismissSave}
                        initFormVal={initFormVal}
                        setInitFormVal={setInitFormVal}
                        handleOnSubmit={handleOnSubmit}
                        devStartDate={devStartDate}
                        setDevStartDate={setDevStartDate}
                        opStartDate={opStartDate}
                        setOpStartDate={setOpStartDate}
                        assetBaseYear={assetBaseYear}
                        setAssetBaseYear={setAssetBaseYear}
                        warningIcon={warningIcon}
                        setWarningIcon={setWarningIcon}
                        isPreview={id}
                        handleSaveDraft={handleSaveDraft}
                        onDraftOpen={onDraftOpen}
                        disableSave={disableSave}
                        steps={steps}
                        isRestore={isRestore}
                        handleMissingPages={handleMissingPages}
                        missingPages={missingPages}
                        onMissingOpen={onMissingOpen}
                        performanceAssetList2={performanceAssetList2}
                        brandedPerformance={brandedPerformance}
                        packagePerformance={packagePerformance}
                        setUnitMixChanged={setUnitMixChanged}
                        unitMixChanged={unitMixChanged}
                        setPackageRooms={setPackageRooms}
                        packageRooms={packageRooms}
                        operatorLookup={operatorLookup}
                        //for step 6 dropdowns
                        setBenchmarkDropdown={setBenchmarkDropdown}
                        benchmarkDropdown={benchmarkDropdown}
                        associatedDropdown={associatedDropdown}
                        setAssociatedDropdown={setAssociatedDropdown}
                        cityOptions={cityOptions}
                        resortOptions={resortOptions}
                        servicedOptions={servicedOptions}
                        //for step 1 dropdowns
                        // setBrandDropdown={setBrandDropdown}
                        // brandDropdown={brandDropdown}
                        occIncRate={occIncRate}
                        setOccIncRate={setOccIncRate}
                        assetData={assetData}
                        isDisabledCalc={isDisabledCalc}
                        setIsDisabledCalc={setIsDisabledCalc}
                        validateKeys={validateKeys}
                        setValidateKeys={setValidateKeys}
                        // firstInitFormVal={firstInitFormVal}
                        setIsDisabledTypes={setIsDisabledTypes}
                        isDisabledTypes={isDisabledTypes}
                        setStepperUpdate={setStepperUpdate}
                        stepperUpdate={stepperUpdate}
                        reassign={location?.state?.from == "Reassign"}
                        validateStep={validateStep}
                        setValidateStep={setValidateStep}
                        onClickedFormulaOpen={onClickedFormulaOpen}
                        onClickedKeysOpen={onClickedKeysOpen}
                        user={user?.roles[0]?.toLowerCase()}
                        id={id}
                        handleProponentOnSubmit={handleProponentOnSubmit}
                        unsetPageClicked={unsetPageClicked}
                        undoUnsetPageClicked={undoUnsetPageClicked}
                        setUpdateDisableNextStep={setUpdateDisableNextStep}
                        updateDisableNextStep={updateDisableNextStep}
                        isModelWarningOpen={isModelWarningOpen}
                        modelWarningOpen={modelWarningOpen}
                        closeModalPath={closeModalPath}
                        setSubmitLoading={setSubmitLoading}
                        isSubmitLoading={isSubmitLoading}
                        getUsers={getUsers}
                        setFieldTouched={setFieldTouched}
                        isFormDirty={isFormDirty}
                        setIsFormDirty={setIsFormDirty}
                        occIncRateGeneral={occIncRateGeneral}
                        setOccIncRateGeneral={setOccIncRateGeneral}
                        setIsCalculateClicked={setIsCalculateClicked}
                        isCalculateClicked={isCalculateClicked}
                      />

                      <ModalAlert
                        isOpen={isDraftOpen}
                        onClose={onDraftClose}
                        title={`${values.projectName == ""
                          ? "You must add project name."
                          : "Are you sure you want to save this as draft?"
                          }`}
                        icon={infoCircle}
                        confirmationModal={
                          values.projectName == "" ? false : true
                        }
                        hasDesc={false}
                        haveSaveDraft={false}
                        path={`/AssetList/${type}`}
                        onPressSaveDraft={() => handleSaveDraft(values)}
                      />
                      <ModalAlert
                        isOpen={isClickedFormulaOpen}
                        onClose={onClickedFormulaClose}
                        title={`${"You must click on 'Calculate' button."}`}
                        icon={infoCircle}
                        confirmationModal={false}
                        hasDesc={false}
                        haveSaveDraft={false}
                        navLinkSubmit={true}
                      //path={`/AssetsHistory`}
                      //pathDraft={`/AssetsHistory/success`}
                      //onPressSaveDraft={() => handleSaveDraft(values)}
                      />
                      <ModalAlert
                        isOpen={isClickedKeysOpen}
                        onClose={onClickedKeysClose}
                        title={`${"The sum of total keys should not exceeed "}${values?.numberOfKeys
                          }`}
                        icon={infoCircle}
                        confirmationModal={false}
                        hasDesc={false}
                        haveSaveDraft={false}
                        navLinkSubmit={true}
                      //path={`/AssetsHistory`}
                      //pathDraft={`/AssetsHistory/success`}
                      //onPressSaveDraft={() => handleSaveDraft(values)}
                      />
                      <ModalAlert
                        isOpen={isCancelOpen}
                        onClose={onCancelClose}
                        title="Are you sure you want to discard the changes?"
                        icon={infoCircle}
                        hasDesc={false}
                        confirmationModal={true}
                        path={closeModalPath}
                        isProponent={
                          user?.roles[0]?.toLowerCase() === "proponent"
                            ? true
                            : false
                        }
                        onPressSaveDraft={() => handleSaveDraft(values)}
                        setDismissSave={() => setDismissSave(true)}
                      />
                    </Form>
                  )}
                </Formik>
              </MainBox>
            </>
          ) : (
            <>
              <Badge
                bg="#EBC03F26"
                className="masterDataBadge"
                width="100%"
                alignSelf={"flex-start"}
              >
                <HStack px="4" py="1">
                  <Tooltip
                    aria-label="A tooltip"
                    bg="#f4f4ed"
                    color="#1A1D1F"
                    borderRadius="5px"
                    border="1px solid #f4f4ed"
                    hasArrow
                    placement="right"
                    px={5}
                    py={2}
                  >
                    <img src={info} alt="tooltip icon" />
                  </Tooltip>
                  <Box fontSize={{ lg: 12, "2xl": 15 }}>
                    <HStack>
                      <Text> Ensure that you add</Text>
                      {filteredDropdowns.map((name) => (
                        <NavLink
                          style={{
                            color: "#007BB5",
                            textDecoration: "underline",
                          }}
                          to={`/MasterDataVersions/${name}`}
                        >
                          <Text color="#007BB5">{name},</Text>
                        </NavLink>
                      ))}
                      <Text>before creating an asset.</Text>
                    </HStack>
                  </Box>
                </HStack>
              </Badge>
            </>
          )}
        </Box>
        <ModalAlert
          isOpen={isMissingOpen}
          onClose={onMissingClose}
          title={
            "Ensure that you fill all missing fields in the following pages"
          }
          icon={infoCircle}
          hasDesc={true}
          desc={missingPages}
          confirmationModal={false}
          isAssetModal={true}
          navLinkSubmit={true}
        />
        <ModalAlert
          isOpen={isErrorOpen}
          onClose={onErrorClose}
          title={errorMessage}
          icon={infoCircle}
          hasDesc={false}
          confirmationModal={false}
        />

        <ModalAlert
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          title={submitModalTitle}
          btnWidthFull={true}
          icon={checked}
          hasDesc={false}
          disableClose={true}
          path={`/AssetsHistory`}
          isSubmitLoading={isSubmitLoading}
        />
      </>
    </div>
  );
};